import { storageManager } from "@app/app";
import { hooks as authHooks, types as authTypes } from "@app/auth";
import { hooks as cartHooks } from "@app/cart";
import { hooks as clientHooks } from "@app/client";
import { hooks as domainHooks } from "@app/domain";
import { hooks as projectHooks } from "@app/project";
import { merge } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { StorageKeys } from "../constants/StorageKeys";
import { useAppState } from "../hooks";
import { IAppState } from "../types";

export interface IWithAppStateResolverEnhancerProps {}

export function withAppStateResolver<T extends IWithAppStateResolverEnhancerProps = IWithAppStateResolverEnhancerProps>(
    WrappedComponent: React.ComponentType<T>
) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

    const DecoratedComponent = (props: Omit<T, keyof IWithAppStateResolverEnhancerProps>) => {
        const appState = useAppState();
        const { mutate: deleteCartItemsMutator } = cartHooks.useDeleteCartItems();
        const [currentAuthUser, setCurrentAuthUser] = useState<authTypes.IAuthData["user"]>();
        const auth = authHooks.useAuth();
        const hostname = window.location.hostname;

        const { projectId: rawUrlProjectId } = useParams();
        const location = useLocation();
        const {
            state: { projectId, clientId, domainId, adminDomainId },
        } = appState;

        const { data: loadedPublicDomain } = domainHooks.useGetPublicDomainItem(hostname);
        const { data: loadedAdminDomain } = domainHooks.useGetDomainItem(adminDomainId || 0, { enabled: !!adminDomainId });
        const { data: loadedDomain } = domainHooks.useGetDomainItem(domainId || 0, { enabled: !!domainId });
        const { data: loadedProject } = projectHooks.useGetProjectItem(projectId || 0, { enabled: !!projectId });
        const { data: loadedClient } = clientHooks.useGetClientItem(clientId || 0, { enabled: !!clientId });

        const urlProjectId = Number(rawUrlProjectId);
        const isClientPage = !location.pathname.includes("admin");

        useEffect(() => {
            const fallbackToDefaults = !urlProjectId && !clientId && !projectId;
            const nextClientId = fallbackToDefaults ? auth?.defaultClientId : clientId;
            // const nextProjectId = fallbackToDefaults ? auth?.defaultProjectId : isClientPage && urlProjectId ? urlProjectId : clientId;
            const nextProjectId = fallbackToDefaults ? auth?.defaultProjectId : isClientPage && urlProjectId ? urlProjectId : projectId;
            const nextDefaultDomainId = appState.state.domainId || auth?.defaultDomainId;

            appState.setState({
                ...(appState.state || {}),
                domainId: nextDefaultDomainId,
                clientId: nextClientId,
                projectId: nextProjectId,
                adminDomainId: appState.state.adminDomainId || nextDefaultDomainId,
            });
        }, [auth.user, auth?.defaultDomainId, projectId, urlProjectId]);

        useEffect(() => {
            if (!auth.user && currentAuthUser) {
                // probably logged out, clear appState...
                appState.setState({
                    domainPublic: appState.state.domainPublic,
                });
            } else if (!currentAuthUser && auth.user) {
                setCurrentAuthUser(auth.user);
            }

            // setAuthUser(auth.user);
        }, [auth.user]);

        useEffect(() => {
            if (loadedProject) {
                appState.setState((currentState) => ({
                    ...(currentState || {}),
                    project: loadedProject,
                    // clientId: appState.state.clientId || loadedProject.client?.id,
                    clientId: loadedProject.client?.id || appState.state.clientId,
                }));
            }
        }, [loadedProject]);

        useEffect(() => {
            if (loadedClient) {
                appState.setState((currentState) => ({
                    ...(currentState || {}),
                    client: loadedClient,
                }));
            }
        }, [loadedClient]);

        useEffect(() => {
            if (loadedAdminDomain) {
                appState.setState((currentState) => ({
                    ...(currentState || {}),
                    adminDomain: loadedAdminDomain,
                }));
            }
        }, [loadedAdminDomain]);

        useEffect(() => {
            if (loadedDomain) {
                appState.setState((currentState) => ({
                    ...(currentState || {}),
                    domain: loadedDomain,
                }));
            }
        }, [loadedDomain]);

        useEffect(() => {
            if (loadedPublicDomain) {
                appState.setState((currentState) => ({
                    ...(currentState || {}),
                    domainPublic: loadedPublicDomain,
                }));
            }
        }, [loadedPublicDomain]);

        useEffect(() => {
            document.title = loadedPublicDomain?.title || "Order portal";
        }, [loadedPublicDomain]);

        // set state in storage on change
        useEffect(() => {
            console.log("appState: ", appState.state);
            storageManager.getGlobalSession().set(StorageKeys.AppState, appState.state);
        }, [appState.state]);

        // restore state from storage initially when component is mount
        useEffect(() => {
            storageManager
                .getGlobalSession()
                .get<IAppState>(StorageKeys.AppState, {})
                .then((storageAppState) => appState.setState((currentState) => merge(currentState, storageAppState)));
        }, []);

        return <WrappedComponent {...(props as T)} />;
    };

    DecoratedComponent.displayName = `withAppStateResolver(${displayName})`;

    return DecoratedComponent;
}
