import { PageTitle } from "@app/admin";
import { ContentWithNavigationSidebarLayout } from "@app/app";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetProjectItem } from "../../hooks";
import { ProjectAdminTabs } from "../ProjectAdminTabs";

export const ProjectTabContentWithNavigationSidebarLayout = () => (
    <ContentWithNavigationSidebarLayout preOutletChildren={<ProjectAdminContentHeader />} />
);

const ProjectAdminContentHeader = () => {
    const { projectId } = useParams<string>();
    const { data: project } = useGetProjectItem(Number(projectId));

    return (
        <React.Fragment>
            <PageTitle title={project?.name || ""} className="mb-0" />
            <ProjectAdminTabs project={project} />
        </React.Fragment>
    );
};
