const hostname = window.location.hostname;

export const config = {
    sanctumUrl: `//${hostname}/sanctum/csrf-cookie`,
    appUrl: `//${hostname}`,
    // sanctumUrl: `//${process.env.REACT_APP_DOMAIN}/sanctum/csrf-cookie`,
    // appUrl: `//${process.env.REACT_APP_DOMAIN}`,
};

/*
appUrl: "//furniture-portal.test"
sanctumUrl: "//furniture-portal.test/sanctum/csrf-cookie"
*/
