import {
    AdjustmentsIcon,
    ArchiveIcon,
    BookOpenIcon,
    BriefcaseIcon,
    ChartBarIcon,
    ChatIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    ClipboardListIcon,
    CogIcon,
    DatabaseIcon,
    DesktopComputerIcon,
    DocumentSearchIcon,
    GlobeAltIcon,
    // HomeIcon,
    IdentificationIcon,
    NewspaperIcon,
    QuestionMarkCircleIcon,
    ReceiptTaxIcon,
    ScaleIcon,
    ShoppingCartIcon,
    SupportIcon,
    TemplateIcon,
    UploadIcon,
    UserCircleIcon,
    UserGroupIcon,
    UsersIcon,
    ViewGridIcon,
    XIcon,
} from "@heroicons/react/outline";
import { HomeIcon } from "@heroicons/react/solid";

/**
 * Mapping between icon name and Icon component.
 */
OutlineIcon.map = {
    "briefcase": BriefcaseIcon,
    "chart-bar": ChartBarIcon,
    "chat": ChatIcon,
    "cog": CogIcon,
    "database": DatabaseIcon,
    "document-search": DocumentSearchIcon,
    "home": HomeIcon,
    "question-mark-circle": QuestionMarkCircleIcon,
    "user-circle": UserCircleIcon,
    "x": XIcon,
    "chevron-up": ChevronUpIcon,
    "chevron-down": ChevronDownIcon,
    "chevron-left": ChevronLeftIcon,
    "chevron-right": ChevronRightIcon,
    "support": SupportIcon,
    "identification": IdentificationIcon,
    "user-group": UserGroupIcon,
    "template": TemplateIcon,
    "scale": ScaleIcon,
    "newspaper": NewspaperIcon,
    "adjustments": AdjustmentsIcon,
    "upload": UploadIcon,
    "view-grid": ViewGridIcon,
    "clipboard-list": ClipboardListIcon,
    "archive": ArchiveIcon,
    "users": UsersIcon,
    "shopping-cart": ShoppingCartIcon,
    "book-open": BookOpenIcon,
    "receipt-tax": ReceiptTaxIcon,
    "globe-alt": GlobeAltIcon,
    "desktop-computer": DesktopComputerIcon,
    "check": CheckIcon,
};

export type TOutlineIconName = keyof typeof OutlineIcon.map;

export interface IOutlineIconProps {
    [key: string]: any;
    name: TOutlineIconName;
}

export function OutlineIcon(props: IOutlineIconProps) {
    const { name, ...iconProps } = props;
    const hasIcon = name in OutlineIcon.map;
    const Icon = OutlineIcon.map[name];

    if (!hasIcon) {
        throw Error(`Invalid icon name: ${name}`);
    }

    return <Icon {...iconProps} />;
}
