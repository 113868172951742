import classnames from "classnames";
import React from "react";

interface ICardProps {
    children: any;
    className?: string;
    style?: React.CSSProperties;
}

export function Card({ children, className, style }: ICardProps) {
    return (
        <div style={style} className={classnames("bg-white border border-slate-200 rounded-lg", className)}>
            {children}
        </div>
    );
}
