import { axios } from "@app/services";
import { IAuthData, IConfirmation } from "./types";

export const getUser = async (): Promise<IAuthData> => {
    const response = await axios.get<{ data: IAuthData }>("auth/check");

    return response.data.data;
};

export interface ILoginProps {
    email: string;
    password: string;
    hostname?: string;
}

export const login = async (props: ILoginProps): Promise<IAuthData> => {
    const response = await axios.post<{ data: IAuthData }>("auth/login", props);

    return response.data.data;
};

export const logout = async () => {
    await axios.get("auth/logout");
};
export interface IRequestPasswordResetProps {
    email: string;
}

export const requestPasswordReset = async (props: IRequestPasswordResetProps) => {
    await axios.post("auth/forgot-password", props);
};

export const switchDomain = async (domain_id: number) => {
    const response = await axios.post("auth/switch-domain", { domain_id });

    return response.data;
};

export const getConfirmation = async (token: string): Promise<IConfirmation> => {
    const response = await axios.get<IConfirmation>(`auth/confirmation/${token}`);

    return response.data;
};

export const updateConfirmation = async (data: any) => {
    const response = await axios.put(`auth/confirmation/${data.token}`, data);

    return response.data;
};
