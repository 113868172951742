import React from "react";
import { IAppState } from "../types";

export type TAppStateResolver = (state: IAppState) => IAppState;
export interface IAppStateContext {
    state: IAppState;
    setState: React.Dispatch<React.SetStateAction<IAppState>>;
}

export const AppStateContext = React.createContext<IAppStateContext>({
    state: {},
    setState: () => {
        throw new Error("AppStateContext not ready yet");
    },
});

AppStateContext.displayName = "AppStateContext";
