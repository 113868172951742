import React, { useState } from "react";
import { IAppState } from "../types";
import { AppStateContext, IAppStateContext } from "./AppStateContext";

export interface IAuthLoaderProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

export function AppStateContextProvider({ children }: IAuthLoaderProviderProps) {
    const [state, setState] = useState<IAppState>({});

    const value: IAppStateContext = React.useMemo(() => ({ state, setState }), [setState, state]);

    return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>;
}
