/* eslint-disable quote-props */
import { useQuery } from "react-query";
import { QueryKeys } from "./constants/QueryKeys";
import { getAdminNavigationItems, getMainNavigationItems, getSuperAdminNavigationItems } from "./queries";
import { INavigationItem } from "./types";

export function useGetMainNavigationItems() {
    return useQuery(QueryKeys.MainNavigationItems, getMainNavigationItems, {
        retry: 0, // TODO: fine tune this value, works for now
        select: selectMainNavigationItems,
        staleTime: Infinity, // TODO: fine tune this value, works for now
    });
}

export function useGetAdminNavigationItems() {
    return useQuery(QueryKeys.AdminNavigationItems, getAdminNavigationItems, {
        retry: 0, // TODO: fine tune this value, works for now
        select: selectMainNavigationItems,
        staleTime: Infinity, // TODO: fine tune this value, works for now
    });
}

export function useGetSuperAdminNavigationItems() {
    return useQuery(QueryKeys.SuperAdminNavigationItems, getSuperAdminNavigationItems, {
        retry: 0, // TODO: fine tune this value, works for now
        select: selectMainNavigationItems,
        staleTime: Infinity, // TODO: fine tune this value, works for now
    });
}

/**
 * Selectors
 */

const selectMainNavigationItems = (items: INavigationItem[]) =>
    items.map((item) => ({
        ...item,
        // icon: mainNavigationIconMap[item.icon as keyof typeof mainNavigationIconMap] || mainNavigationIconMap.home, // map server side icon names to client side icons
    }));

/**
 * Private/protected
 */

const mainNavigationIconMap = {
    home: "home",
    ecommerce: "briefcase",
    interactions: "document-search",
    targeting: "chat",
    analytics: "chart-bar",
    profile: "database",
    settings: "cog",
    help: "question-mark-circle",
    super: "user-circle",
    default: "cog",
};
