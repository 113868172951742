import { Popover } from "@headlessui/react";
import React from "react";
import { INavigationItem } from "../types";
import { HorizontalDropdownNavigationMainItem } from "./HorizontalDropdownNavigationMainItem";

export interface IHorizontalDropdownNavigationProps {
    items: INavigationItem[] | undefined;
    isExpandable?: boolean;
}

export const HorizontalDropdownNavigation = ({ items, isExpandable = true }: IHorizontalDropdownNavigationProps) => (
    <Popover.Group className="hidden lg:flex-1 lg:block lg:self-stretch">
        <div className="h-full flex space-x-8">
            {(items || []).map((item) => (
                <HorizontalDropdownNavigationMainItem item={item} key={item.id} isExpandable={isExpandable} />
            ))}
        </div>
    </Popover.Group>
);
