import React from "react";
import { FulfillingBouncingCircleSpinner } from "react-epic-spinners";

interface ILoadingMaskProps {
    isLoading: boolean;
    children?: JSX.Element | JSX.Element[] | React.ReactNode;
}

export function LoadingMask({ isLoading, children }: ILoadingMaskProps) {
    return !isLoading ? (
        <React.Fragment>{children}</React.Fragment>
    ) : (
        <div className="w-full h-full bg-slate-50 flex justify-center items-center relative min-h-42">
            <FulfillingBouncingCircleSpinner color="#4f46e5" size={65} />
        </div>
    );
}
