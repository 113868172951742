import classNames from "classnames";
import React from "react";
import { Link, useMatch } from "react-router-dom";
import { INavigationItem } from "../types";

interface ISubNavigationItemLinkProps {
    subItem: INavigationItem;
}

export function SubNavigationItemLink({ subItem }: ISubNavigationItemLinkProps) {
    const to = subItem.to || "";
    const isActive = useMatch(to);
    const isAbsolutePath = to.startsWith("https://");

    return (
        <Link
            key={subItem.title}
            to={to}
            target={`${isAbsolutePath ? "_blank" : ""}`}
            className={classNames(
                "py-2 px-2 ml-8 flex items-center text-sm rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-900",
                {
                    "text-indigo-700": isActive,
                    "border-transparent text-gray-500": !isActive,
                }
            )}
            aria-current={isActive ? "page" : undefined}>
            {subItem.title}
        </Link>
    );
}
