import { ILinkTabItem, LinkTabs } from "@ui";
import React from "react";
import { IProject } from "../types";

export interface IProjectAdminTabsProps {
    project: IProject | undefined;
}

export const ProjectAdminTabs = ({ project }: IProjectAdminTabsProps) => {
    const tabItems: ILinkTabItem[] = [
        {
            name: "Dashboard",
            to: `/admin/projects/${project?.id}/dashboard`,
            icon: "desktop-computer",
        },
        {
            name: "Settings",
            to: `/admin/projects/${project?.id}/settings`,
            icon: "clipboard-list",
        },
        {
            name: "Project products",
            to: `/admin/projects/${project?.id}/products`,
            icon: "archive",
        },
        {
            name: "Project orders",
            to: `/admin/projects/${project?.id}/orders`,
            icon: "shopping-cart",
        },
        {
            name: "Project users",
            to: `/admin/projects/${project?.id}/users`,
            icon: "users",
        },
    ];

    return <LinkTabs items={tabItems} className="mb-8" />;
};
