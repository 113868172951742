import { ILinkTabItem, LinkTabs } from "@ui";
import React from "react";
import { IClient } from "../types";

export interface IClientAdminTabsProps {
    client: IClient | undefined;
}

export const ClientAdminTabs = ({ client }: IClientAdminTabsProps) => {
    const tabItems: ILinkTabItem[] = [
        {
            name: "Client dashboard",
            to: `/admin/clients/${client?.id}/dashboard`,
            icon: "desktop-computer",
        },
        {
            name: "Client settings",
            to: `/admin/clients/${client?.id}/settings`,
            icon: "clipboard-list",
        },
        {
            name: "Client branches",
            to: `/admin/clients/${client?.id}/branches`,
            icon: "archive",
        },
        {
            name: "Client projects",
            to: `/admin/clients/${client?.id}/projects`,
            icon: "clipboard-list",
        },
        // {
        //     name: "Products",
        //     to: `/admin/clients/${client?.id}/products`,
        //     icon: "archive",
        // },
        // {
        //     name: "Orders",
        //     to: `/admin/clients/${client?.id}/orders`,
        //     icon: "shopping-cart",
        // },
        // {
        //     name: "Users",
        //     to: `/admin/clients/${client?.id}/users`,
        //     icon: "users",
        // },
    ];

    return <LinkTabs items={tabItems} className="mb-8" />;
};
