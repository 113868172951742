import { StorageManager } from "@app/common";
import { SessionStorage, Storage, StorageAdapterLocalStorage, TimeUnits } from "@hotelchamp/common";
import { StorageTypes } from "../../common/constants/StorageTypes";

export const storageManager = new StorageManager();

storageManager.registerByType(
    StorageTypes.Global,
    new Storage({
        adapter: new StorageAdapterLocalStorage({
            rootKey: "__fp__.local",
        }),
    })
);

storageManager.registerByType(
    StorageTypes.GlobalSession,
    new SessionStorage({
        adapter: new StorageAdapterLocalStorage({
            rootKey: "__fp__.session",
        }),
        expire: TimeUnits.Minute * 30,
    })
);
