import { OutlineIcon } from "@app/common";
import { Disclosure, Transition } from "@headlessui/react";
import classnames from "classnames";
import React from "react";
import { Link, useMatch } from "react-router-dom";
import { INavigationItem } from "../types";
import { SubNavigationItemLink } from "./SubNavigationItemLink";

interface INavigationItemLinkProps {
    item: INavigationItem;
}

export function NavigationItemLink({ item }: INavigationItemLinkProps) {
    const to = item.to || "";
    const isActive = !!useMatch(to);
    const isAbsolutePath = to.startsWith("https://");
    const iconName: keyof typeof OutlineIcon.map = (item.icon as keyof typeof OutlineIcon.map) || "cog";

    return (
        <div key={item.title}>
            {!item?.items?.length ? (
                <Link
                    key={item.title}
                    to={to}
                    target={`${isAbsolutePath ? "_blank" : ""}`}
                    className={classnames(
                        isActive ? "text-indigo-700" : "border-transparent text-gray-500 hover:bg-gray-50 hover:text-gray-700",
                        "py-2 flex items-center text-sm font-medium rounded-md "
                    )}
                    aria-current={isActive ? "page" : undefined}>
                    <OutlineIcon
                        name={iconName}
                        className={classnames(
                            isActive ? "text-indigo-700" : "text-gray-400 group-hover:text-gray-700",
                            "mr-3 flex-shrink-0 h-5 w-5"
                        )}
                        aria-hidden="true"
                    />
                    {item.title}
                </Link>
            ) : (
                <Disclosure as="div" key={item.title} className="space-y-1" defaultOpen={isActive}>
                    {({ open }) => (
                        <React.Fragment>
                            <Disclosure.Button
                                className={classnames(
                                    "group w-full flex items-center py-2 text-left text-sm font-medium rounded-md focus:outline-none",
                                    {
                                        "text-indigo-700": isActive,
                                        "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-700": !isActive,
                                    }
                                )}>
                                <OutlineIcon
                                    name={iconName}
                                    className={classnames("mr-3 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500", {
                                        "text-indigo-700 group-hover:text-gray-900": isActive,
                                    })}
                                    aria-hidden="true"
                                />
                                <span
                                    className={classnames("flex-1", {
                                        "text-indigo-700 group-hover:text-gray-900": isActive,
                                    })}>
                                    {item.title}
                                </span>

                                <OutlineIcon
                                    name={open ? "chevron-up" : "chevron-down"}
                                    className={classnames("h-4 w-4 text-gray-400", {
                                        "text-indigo-700": isActive,
                                        "ml-3 flex-shrink-0 transform transition-colors": !isActive,
                                    })}
                                    aria-hidden="true"
                                />
                            </Disclosure.Button>
                            <Transition
                                show={open}
                                enter="transition duration-100 ease-out"
                                enterFrom="transform opacity-0"
                                enterTo="transform duration-500 opacity-100"
                                leave="transition duration-0 ease-out"
                                leaveFrom="transform opacity-100"
                                leaveTo="transform opacity-0">
                                <Disclosure.Panel className="space-y-1">
                                    {item.items?.map((subItem, subItemIndex) => (
                                        <SubNavigationItemLink key={subItemIndex} subItem={subItem} />
                                    ))}
                                </Disclosure.Panel>
                            </Transition>
                        </React.Fragment>
                    )}
                </Disclosure>
            )}
        </div>
    );
}
