/* eslint-disable jsx-a11y/anchor-is-valid */
import { hooks as authHooks } from "@app/auth";
import { config } from "@app/auth/config";
import { hooks as domainHooks } from "@app/domain";
import { hooks as navigationHooks, MobileNavigationMenu, Navigation, navigationUtils } from "@app/navigation";
import { HasOneOfRoles, Roles } from "@app/rbac";
import { BookOpenIcon, MenuIcon } from "@heroicons/react/outline";
import { Avatar, AvatarSizes } from "@ui";
import classnames from "classnames";
import React, { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppState } from "../hooks";
import { DomainSwitcher } from "./DomainSwitcher";

export interface ISidebarProps {}

export function Sidebar(props: ISidebarProps) {
    const navigate = useNavigate();
    const {
        setState: setAppState,
        state: { projectId, adminDomainId, adminDomain },
    } = useAppState();
    const { data: adminData } = navigationHooks.useGetAdminNavigationItems();
    const { data: superAdminData } = navigationHooks.useGetSuperAdminNavigationItems();
    const adminNavigationItems = adminData || [];
    const superAdminNavigationItems = superAdminData || [];
    const auth = authHooks.useAuth();
    const userDomains = auth.domains;
    // const { mutateAsync: mutateActiveDomain } = authHooks.useUpdateSwitchDomain();
    const { data: projects } = domainHooks.useGetDomainProjectItems(adminDomainId || 0, { enabled: !!adminDomainId });
    const processedAdminNavigationItems = useMemo(
        () => navigationUtils.replaceNavigationItemsPlaceholders(adminNavigationItems, { projectId, domainId: adminDomainId }),
        [adminNavigationItems, projectId, adminDomainId]
    );
    const processedSuperAdminNavigationItems = useMemo(
        () => navigationUtils.replaceNavigationItemsPlaceholders(superAdminNavigationItems, { projectId, domainId: adminDomain }),
        [superAdminNavigationItems, projectId, adminDomainId]
    );

    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    // const { data: navigationItems } = navigationHooks.useGetMainNavigationItems();

    const handleMobileNavigationToggle = () => {
        setMobileNavOpen((actualIsOpen) => !actualIsOpen);
    };

    const handleSwitch = (toDomainId: number) => {
        // mutateActiveDomain(toDomainId);
        setAppState((appState) => ({ ...appState, adminDomainId: toDomainId }));
    };

    const activateFrontendProject = (activateProjectId: number) => {
        setAppState((appState) => ({ ...appState, projectId: activateProjectId }));
    };

    const handleProjectClick = (clickedProjectId: number) => {
        activateFrontendProject(clickedProjectId);

        navigate(`/admin/projects/${clickedProjectId}/dashboard`);
    };

    return (
        <React.Fragment>
            <div className="md:hidden lg:hidden absolute right-6 top-6 z-50">
                <button
                    type="button"
                    className="text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500"
                    onClick={handleMobileNavigationToggle}>
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-8 w-8" aria-hidden="true" />
                </button>
            </div>

            <MobileNavigationMenu isOpen={isMobileNavOpen} onClose={() => setMobileNavOpen(false)} onOpen={() => setMobileNavOpen(true)}>
                <React.Fragment>
                    <nav className="flex flex-col flex-grow overflow-y-auto">
                        <div className="px-8 flex items-center">
                            <img className="h-14 w-auto " src={`${config.appUrl}/assets/img/logo.svg`} alt="" />
                        </div>
                        <div className="flex-grow mt-8">
                            <div className="space-y-4 mx-4">
                                <DomainSwitcher activeDomain={adminDomain} domains={userDomains} onChange={handleSwitch} />
                                <div className="">
                                    <Navigation items={processedAdminNavigationItems} />
                                    <div className="w-full border-t border-gray-200 my-4" />
                                    <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wider mt-4">Projects</h3>
                                    {projects?.map((project) => (
                                        <div
                                            key={project.id}
                                            onClick={() => handleProjectClick(project.id)}
                                            className={classnames(
                                                "py-1.5 mx-0.5 text-gray-500 flex items-center text-sm rounded-md capitalize cursor-pointer hover:text-gray-700",
                                                {
                                                    "opacity-60": !project.is_active,
                                                }
                                            )}>
                                            <div
                                                style={{ backgroundColor: project.color }}
                                                className={classnames("w-2.5 h-2.5 rounded-full mr-2", { "bg-purple-600": !project.color })}
                                            />
                                            {project.name}
                                        </div>
                                    ))}
                                    <div className="w-full border-t border-gray-200 my-4" />
                                    <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wider mt-4">Super admin</h3>
                                    <Navigation items={superAdminNavigationItems} />
                                </div>
                            </div>
                        </div>
                        <div className="flex-shrink-0 block w-full">
                            {/* <Navigation items={secondaryNavigation} /> */}
                            <div className="flex-shrink-0 flex border-t border-slate-100 p-4 mt-4">
                                <div className="flex-shrink-0 w-full group block">
                                    <Link to={`/admin/user/${auth.user.id}`}>
                                        <div className="flex items-center cursor-pointer">
                                            <Avatar
                                                url={auth.user.avatar?.preview_url}
                                                fallBackInitials={auth.user.name}
                                                size={AvatarSizes.Small}
                                            />
                                            <div className="ml-3">
                                                <p className="text-sm font-medium text-slate-700 group-hover:text-slate-900">
                                                    {auth.user.name}
                                                </p>
                                                <p className="text-xs font-medium text-slate-500 group-hover:text-slate-700">
                                                    View profile
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </nav>
                </React.Fragment>
            </MobileNavigationMenu>

            {/* Static sidebar for desktop */}
            <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <nav className="bg-white border-r border-slate-100 pt-5 flex flex-col flex-grow overflow-y-auto">
                    <div className="px-8 flex items-center items-center w-full">
                        <img className="h-12 w-44 " src={`${config.appUrl}/assets/img/logo.svg`} alt="" />
                    </div>
                    <div className="flex-grow mt-8">
                        <div className="space-y-4 mx-4">
                            <DomainSwitcher activeDomain={adminDomain} domains={userDomains} onChange={handleSwitch} />
                            <div className="">
                                <Navigation items={processedAdminNavigationItems} />
                                <div className="w-full border-t border-gray-200 my-4" />
                                <HasOneOfRoles role={[Roles.SuperAdmin]}>
                                    <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wider mt-4">Super admin</h3>
                                    <Navigation items={processedSuperAdminNavigationItems} />
                                    <div className="w-full border-t border-gray-200 my-4" />
                                </HasOneOfRoles>

                                <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wider mt-4">Projects</h3>
                                {projects?.map((project) => (
                                    <div
                                        key={project.id}
                                        title={!project.is_active ? `${project.name} in active` : project.name}
                                        onClick={() => handleProjectClick(project.id)}
                                        className={classnames(
                                            "py-1.5 mx-0.5 text-gray-500 flex items-center text-sm rounded-md capitalize cursor-pointer hover:text-gray-700",
                                            {
                                                "opacity-60 line-through": !project.is_active,
                                            }
                                        )}>
                                        <div
                                            style={{ backgroundColor: project.color }}
                                            className={classnames("w-2.5 h-2.5 rounded-full mr-2", { "bg-purple-600": !project.color })}
                                        />
                                        {project.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex-shrink-0 block w-full">
                        <HasOneOfRoles role={[Roles.Admin, Roles.SuperAdmin]}>
                            <button
                                type="button"
                                title="Back to catalog"
                                onClick={() => navigate(`/projects/${projectId}/catalog`)}
                                className="mb-2 ml-2 py-2 px-2 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500">
                                <BookOpenIcon className="h-4 w-4 flex-shrink-0" aria-hidden="true" />

                                <span className="sr-only">Catalog</span>
                            </button>
                        </HasOneOfRoles>
                        {/* <Navigation items={secondaryNavigation} /> */}
                        <div className="flex-shrink-0 flex border-t border-slate-100 p-4 mt">
                            <div className="flex-shrink-0 w-full group block">
                                <Link to={`/admin/user/${auth.user.id}`}>
                                    <div className="flex items-center cursor-pointer">
                                        <Avatar
                                            url={auth.user.avatar?.preview_url}
                                            fallBackInitials={auth.user.name}
                                            size={AvatarSizes.Small}
                                        />
                                        <div className="ml-3">
                                            <p className="text-sm font-medium text-slate-700 group-hover:text-slate-900">
                                                {auth.user.name}
                                            </p>
                                            <p className="text-xs font-medium text-slate-500 group-hover:text-slate-700">View profile</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </React.Fragment>
    );
}
