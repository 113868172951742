import { hooks as authHooks } from "@app/auth";
import React from "react";
import { Roles } from "../constants/Roles";
import { hasOneOfRoles } from "../utils/rbacUtils";

export interface IHasRoleProps {
    role: Roles | Roles[];
    fallback?: React.ReactNode | React.ReactNode[];
    children: React.ReactNode | React.ReactNode[];
}

export const HasOneOfRoles = ({ role, children, fallback }: IHasRoleProps) => {
    const auth = authHooks.useAuth();
    const userRoles = auth?.user?.roles || [];
    const requiredRoles = Array.isArray(role) ? role : [role];

    return <React.Fragment>{hasOneOfRoles(userRoles, requiredRoles) ? children : fallback}</React.Fragment>;
};
