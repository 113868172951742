import i18nextLibrary from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import { initReactI18next } from "react-i18next";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18nextLibrary.init({
    fallbackLng: "nl",
    debug: true,

    resources: {
        en: {
            "common": {
                "navigate": {
                    back: "Back",
                },
                "text": {
                    "not-allowed": "Not allowed",
                    "incomplete-setup": "Incomplete setup",
                },
                "search": "Search",
                "validate": {
                    "is-required": "Is required",
                    "required": "Is required",
                    "min-length": "Is min length of {{value}}",
                    "max-length": "Is max length of {{value}}",
                    "min": "Is min {{value}}",
                    "max": "Is max {{value}}",
                    "present": "Is present",
                    "email": "Is valid email address",
                },
                "error-dialog": {
                    "title": "System message",
                    "description": "Oops, something went wrong",
                    "text-content": "Something went wrong",
                    "button-try-again": "Try again",
                    "button-cancel": "Cancel",
                },
            },
            "address": {
                form: {
                    "title": "Address",
                    "description": "Change data on a given address",
                    "label-first-name": "First name",
                    "label-surname": "Last name",
                    "label-street": "Street",
                    "label-house-number": "Number",
                    "label-extended": "Extended",
                    "label-zip-code": "Zip code",
                    "label-city": "City",
                    "label-country": "Country",
                    "placeholder-country": "Select...",
                    "label-phone-number": "Phone number",
                    "label-description": "Description",
                    "placeholder-description": "Warehouse",
                    "button-save": "Save",
                    "button-cancel": "Cancel",
                },
            },
            "address-field": {
                "button-change": "Change",
                "button-create": "Create",
                "text-no-address": "No address",
            },
            "navigation": {
                "item": {
                    "projects-catalog": "Catalog",
                    "projects-orders": "Orders",
                },
                "admin": {
                    item: {
                        "domains-dashboard": "Dashboard",
                        "domains-clients": "Clients",
                        "domains-products": "Products",
                        "clients-projects": "Projects",
                        "domains-users": "Users",
                        "domains-orders": "Orders",
                        "domains-item": "",
                        "domains-product-sections": "Product sections",
                    },
                },
                "super-admin": {
                    item: {
                        "users": "Users",
                        "taxes": "Taxes",
                        "domains": "Domains",
                        "mail-templates": "Mail templates",
                    },
                },
            },
            "branch": {
                form: {
                    "field-shipping-address-custom-input-only-option": "Provide custom a address",
                    "field-shipping-address-custom-input-option": "Please provide your shipping address",
                    "button-change": "Change",
                    "button-create": "Create",
                },
            },
            "cart": {
                "bar-item": {
                    "sr-button-label": "Cart",
                    "header": "Shopping cart",
                    "button-label-checkout": "Checkout",
                    "button-label-view-cart": "View cart",
                    "link-label-continue-shopping": "Continue shopping",
                    "text-no-items": "No products in cart",
                },
                "item-list": {
                    "header": "Products in your cart",
                    "button-label-remove": "Remove",
                },
                "summary": {
                    "header": "Order overview",
                    "button-label-checkout": "Checkout",
                    "link-label-continue-shopping": "Go back shopping",
                    "or": "Or",
                    "subtotal": "Subtotal",
                    "tax": "Tax",
                    "total": "Total",
                },
                "added-to-cart-dialog": {
                    "title": "Your shopping cart",
                    "description": "New items added",
                    "content": '"{{name}}" added to your shopping cart. Check your items through the cart top right',
                    "button-go-to-cart": "See you cart",
                    "button-continue-shopping": "Continue shopping",
                },
            },
            "project": {
                page: {
                    "project-cart-overview": {
                        title: "Cart overview",
                        description: "",
                    },
                    "checkout": {
                        title: "Checkout",
                        description: "",
                    },
                    "order-overview": {
                        header: "Orders for project {{project}}",
                        description: "",
                    },
                    "order-item": {
                        title: "Order {{reference}}",
                    },
                    "checkout-done": {
                        header: "Order done",
                        description: "We will send an e-mail with your order details to:",
                    },
                },
            },
            "catalog": {
                grid: {
                    "header": "Products",
                    "item": {
                        "link-label-more-info": "More info",
                        "button-label-add-to-cart": "Add",
                        "added": "Added",
                        "price-from": "Starting at",
                    },
                    "text-in-stock": "in stock",
                    "text-no-stock": "Not in stock",
                },
                item: {
                    "header": "Product information",
                    "select-placeholder-qty": "Qty",
                    "button-label-add-to-cart": "Att to cart",
                    "button-label-not-available": "Not available",
                    "button-label-edit": "Edit product",
                    "variant-attribute-label-size": "Size",
                    "variant-attribute-label-color": "Color",
                    "price-from": "Starting at",
                },
                page: {
                    catalog: {
                        header: "Catalog",
                        description: "",
                    },
                },
            },
            "order": {
                form: {
                    "contact_details": "Shipping details",
                    "field": {
                        "label-shipping-address": "Shipping address",
                        "first-name": "First name",
                        "last-name": "Last name",
                        "street": "Street",
                        "street-number": "Number",
                        "street-number-extended": "Extended",
                        "zipcode": "Zipcode",
                        "city": "City",
                        "country": "Country",
                        "phone-number": "Phone",
                        "description": "Description",
                        "is_attendant_present_at_delivery": "Attendant present at delivery?",
                        "remarks": "Other comments (e.g. different delivery address or urgency)",
                        "notification_number": "Notification number",
                        "cost_center": "Cost center",
                    },
                    "payment": "Payment details",
                    "order-summary": "Order summary",
                    "button-label-create-order": "Confirm order",
                    "button-label-save-order": "Save",
                    "button-label-creating-order": "Saving order",
                    "button-label-cancel": "Cancel",
                },
                table: {
                    "header": {
                        "project": "Project",
                        "status": "Status",
                        "reference": "Reference",
                        "created-at": "Created at",
                    },
                    "button-label-view": "View",
                },
                summary: {
                    address: "Address",
                    phoneNumber: "Phone number",
                    subtotal: "Subtotal",
                    tax: "Tax",
                    total: "Total",
                },
                orderProducts: {
                    quantity: "Quantity",
                    price: "Price",
                },
            },
            "auth": {
                page: {
                    login: {
                        header: {
                            title: "Sign in to your customer portal",
                        },
                        form: {
                            field: {
                                "label-email": "Email",
                                "placeholder-email": "Your email address",
                                "label-remember-me": "Remember me?",
                                "label-password": "Password",
                                "placeholder-password": "Password",
                                "validate-invalid-login": "Invalid username/password combination",
                                "button-label-sign-in": "Sign in",
                            },
                        },
                    },
                },
            },
            "user": {
                "actions-dropdown": {
                    "button-user-settings": "User settings",
                    "button-logout": "Logout",
                },
            },
        },
        nl: {
            "common": {
                "text": {
                    "not-allowed": "Geen toegang",
                    "incomplete-setup": "Setup is niet volledig",
                },
                "error-dialog": {
                    "title": "Systeem melding",
                    "description": "Oeps, er is wat verkeerd gegaan",
                    "text-content": "Melding",
                    "button-try-again": "Probeer het opnieuw",
                    "button-cancel": "Annuleren",
                },
                "navigate": {
                    back: "Terug",
                },
                "search": "Zoeken",
                "validate": {
                    "is-required": "Is verplicht",
                    "required": "Is verplicht",
                    "min-length": "Heeft minimale lengte van {{value}}",
                    "max-length": "Heeft maximale lengte van {{value}}",
                    "min": "Is min {{value}}",
                    "max": "Is max {{value}}",
                    "present": "Is aanwezig",
                    "email": "Is een geldig emailadres",
                },
            },
            "address": {
                form: {
                    "title": "Adres",
                    "description": "Pas adresgegevens aan",
                    "label-first-name": "Voornaam",
                    "label-surname": "Achternaam",
                    "label-street": "Straat",
                    "label-house-number": "Nummer",
                    "label-extended": "Toevoeging",
                    "label-zip-code": "Postcode",
                    "label-city": "Plaats",
                    "label-country": "Land",
                    "placeholder-country": "Selecteer",
                    "label-phone-number": "Telefoonnummer",
                    "label-description": "Omschrijving",
                    "placeholder-description": "Magazijn",
                    "button-save": "Opslaan",
                    "button-cancel": "Annuleer",
                },
            },
            "address-field": {
                "button-change": "Aanpassen",
                "button-create": "Creëer",
                "text-no-address": "Geen gegevens",
            },
            "navigation": {
                "item": {
                    "projects-catalog": "Catalogus",
                    "projects-orders": "Bestellingen",
                },
                "admin": {
                    item: {
                        "domains-dashboard": "Dashboard",
                        "domains-clients": "Cliënten",
                        "domains-products": "Producten",
                        "clients-projects": "Projecten",
                        "domains-users": "Teamgenoten",
                        "domains-orders": "Bestellingen",
                        "domains-item": "Instellingen",
                        "domains-product-sections": "Product secties",
                    },
                },
                "super-admin": {
                    item: {
                        "users": "Gebruikers",
                        "taxes": "Belastingen",
                        "domains": "Domeinen",
                        "mail-templates": "E-mail templates",
                    },
                },
            },
            "branch": {
                form: {
                    "field-shipping-address-custom-input-only-option": "Geef een aangepast adres",
                    "field-shipping-address-custom-input-option": "Stel je verzendadres in",
                    "button-change": "Aanpassen",
                    "button-create": "Creëer",
                },
            },
            "cart": {
                "bar-item": {
                    "sr-button-label": "Winkelwagen",
                    "header": "Winkelwagen",
                    "button-label-checkout": "Bestellen",
                    "button-label-view-cart": "Bekijk winkelwagen",
                    "link-label-continue-shopping": "Verder winkelen",
                    "text-no-items": "Geen producten om weer te geven",
                },
                "item-list": {
                    "header": "Producten in winkelwagen",
                    "button-label-remove": "Verwijderen",
                },
                "summary": {
                    "header": "Bestel overzicht",
                    "button-label-checkout": "Bestellen",
                    "link-label-continue-shopping": "Ga verder met winkelen",
                    "or": "Of",
                    "subtotal": "Subtotaal",
                    "tax": "BTW",
                    "total": "Totaal",
                },
                "added-to-cart-dialog": {
                    "title": "Winkelwagen",
                    "description": "Nieuwe producten toegevoegd",
                    "content": '"{{name}}" aan de winkelwagen toegevoegd. Zie de winkelwagen rechtsboven om voortaan de inhoud te bekijken',
                    "button-go-to-cart": "Winkelwagen",
                    "button-continue-shopping": "Verder winkelen",
                },
            },
            "project": {
                page: {
                    "project-cart-overview": {
                        title: "Winkelwagen overzicht",
                        description: "",
                    },
                    "checkout": {
                        title: "Bestellen",
                        description: "",
                    },
                    "order-overview": {
                        header: "Bestellingen voor project {{project}}",
                        description: "",
                    },
                    "order-item": {
                        title: "Bestelling {{reference}}",
                    },
                    "checkout-done": {
                        header: "Alles is geregeld.",
                        description: "We sturen een bevestiging en nieuws over de bezorging naar je e-mailadres.",
                    },
                },
            },
            "catalog": {
                grid: {
                    "header": "producten",
                    "item": {
                        "link-label-more-info": "Meer info",
                        "button-label-add-to-cart": "Toevoegen",
                        "added": "Toegevoegd",
                        "price-from": "Vanaf",
                    },
                    "text-in-stock": "op voorraad",
                    "text-no-stock": "Niet op voorraad",
                },
                item: {
                    "header": "Product informatie",
                    "select-placeholder-qty": "Aantal",
                    "button-label-add-to-cart": "Winkelwagen",
                    "button-label-not-available": "Niet beschikbaar",
                    "button-label-edit": "Bewerken",
                    "variant-attribute-label-size": "Maat",
                    "variant-attribute-label-color": "Kleur",
                    "price-from": "Vanaf",
                },
                page: {
                    catalog: {
                        header: "Catalogus",
                        description: "",
                    },
                },
            },
            "order": {
                form: {
                    "contact_details": "Verzendgegevens",
                    "field": {
                        "label-shipping-address": "Aflever adres",
                        "first-name": "Voornaam",
                        "last-name": "Achternaam",
                        "street": "Straat",
                        "street-number": "Nummer",
                        "street-number-extended": "Toevoeging",
                        "zipcode": "Postcode",
                        "city": "Stad",
                        "country": "Land",
                        "phone-number": "Telefoon",
                        "description": "Omschrijving",
                        "cost_center": "Kostenplaats",
                        "is_attendant_present_at_delivery": "Begeleider aanwezig bij levering?",
                        "remarks": "Overige opmerkingen (bv ander afleveradres of spoed)",
                        "notification_number": "Meldingsnummer",
                    },
                    "payment": "Betaal gegevens",
                    "order-summary": "Bestel overzicht",
                    "button-label-create-order": "Bestelling plaatsen",
                    "button-label-save-order": "Opslaan",
                    "button-label-creating-order": "Bezig met opslaan",
                    "button-label-cancel": "Annuleren",
                },
                table: {
                    "header": {
                        "project": "Project",
                        "status": "Status",
                        "reference": "Referentie",
                        "created-at": "Gemaakt op",
                    },
                    "button-label-view": "Bekijken",
                },
                summary: {
                    address: "Adres",
                    phoneNumber: "Telefoonnummer",
                    subtotal: "Subtotaal",
                    tax: "BTW bedrag",
                    total: "Totaal prijs",
                },
                orderProducts: {
                    quantity: "Aantal",
                    price: "Prijs",
                },
            },
            "auth": {
                page: {
                    login: {
                        header: {
                            title: "Login op het klantenportaal",
                        },
                        form: {
                            field: {
                                "label-email": "Emailadres",
                                "label-password": "Wachtwoord",
                                "label-remember-me": "Onthoud mij?",
                                "placeholder-email": "Je emailadres",
                                "placeholder-password": "Wachtwoord",
                                "validate-invalid-login": "Verkeerde combinatie van gebruikersnaam en wachtwoord",
                                "button-label-sign-in": "Inloggen",
                            },
                        },
                    },
                },
            },
            "user": {
                "actions-dropdown": {
                    "button-user-settings": "Gebruiker instellingen",
                    "button-logout": "Uitloggen",
                },
            },
        },
    },

    // interpolation: {
    //     escapeValue: false, // not needed for react as it escapes by default
    // },
});

export const i18next = i18nextLibrary;

/*
const initialAdminNavigationItems: INavigationItem[] = decorateItems([
    {
        id: 11,
        title: "navigation:admin.item.domains-dashboard",
        icon: "home",
        to: "/admin/domains/dashboard",
    },
    {
        id: 11,
        title: "navigation:admin.item.domains-clients",
        icon: "archive",
        to: "/admin/domains/clients",
    },
    {
        id: 11,
        title: "navigation:admin.item.domains-products",
        icon: "archive",
        to: "/admin/domains/products",
    },
    {
        id: 12,
        title: "navigation:admin.item.clients-projects",
        icon: "clipboard-list",
        to: "/admin/domains/projects",
    },
    {
        id: 13,
        title: "navigation:admin.item.domains-users",
        icon: "users",
        to: "/admin/domains/users",
    },
    {
        id: 4,
        title: "navigation:admin.item.domains-orders",
        icon: "shopping-cart",
        to: "/admin/domains/orders",
        items: [],
    },
    {
        id: 2,
        title: "navigation:admin.item.domains-item",
        icon: "globe-alt",
        to: "/admin/domains/1",
    },
]);

const initialSuperAdminNavigationItems: INavigationItem[] = decorateItems([
    {
        id: 1311,
        title: "navigation:super-admin.item.users",
        icon: "users",
        to: "/admin/super/users",
    },
    {
        id: 1411,
        title: "navigation:super-admin.item.taxes",
        icon: "receipt-tax",
        to: "/admin/super/taxes",
    },
    {
        id: 1511,
        title: "navigation:super-admin.item.domains",
        icon: "globe-alt",
        to: "/admin/super/domains",
    },
    {
        id: 1512,
        title: "navigation:super-admin.item.mail-templates",
        icon: "globe-alt",
        to: "/admin/super/mail-templates",
    },
]);
*/
