import classnames from "classnames";
import React from "react";

export interface IAvatarProps {
    url: string | undefined;
    fallBackInitials: string;
    className?: string;
    size?: AvatarSizes;
    style?: React.CSSProperties;
}

export enum AvatarSizes {
    Small = "small",
    Medium = "medium",
    Large = "large",
}

export function Avatar({ url, fallBackInitials, size = AvatarSizes.Medium, className, style }: IAvatarProps) {
    const initials =
        fallBackInitials.split(" ")[0].charAt(0) +
        (fallBackInitials.split(" ")[1]?.charAt(0) || fallBackInitials.split("-")[1]?.charAt(0) || fallBackInitials[1]);

    return (
        <div
            style={style}
            className={classnames(
                "text-xs text-brand_blue-800 uppercase rounded-full flex items-center justify-center overflow-hidden",
                className,
                {
                    "w-8 h-8": size === AvatarSizes.Small,
                    "w-12 h-12": size === AvatarSizes.Medium,
                    "w-16 h-16": size === AvatarSizes.Large,
                    "bg-brand_blue-400": !url,
                }
            )}>
            {url ? <img src={`${url}`} alt={fallBackInitials} className="object-contain h-10 w-10" /> : <div>{initials}</div>}
        </div>
    );
}
