import { OutlineIcon, TOutlineIconName } from "@app/common";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { matchPath, NavLink, useLocation, useNavigate } from "react-router-dom";

export interface ILinkTabItem {
    name: string;
    icon?: string;
    info?: number | string;
    disabled?: boolean;
    to: string;
}

export interface ILinkTabsProps {
    className?: string;
    items: ILinkTabItem[];
}

export function LinkTabs({ items, className }: ILinkTabsProps) {
    const [activeItem, setActiveItem] = useState<ILinkTabItem>();
    const navigate = useNavigate();
    const location = useLocation();

    const handleMobileOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = event.currentTarget.value;

        if (newValue) {
            navigate(newValue);
        }
    };

    useEffect(() => {
        const foundActiveItem = items.find((item) => !!matchPath(item.to, location.pathname));

        setActiveItem(foundActiveItem);
    }, [location.pathname, items]);

    return (
        <div className={className}>
            <div className="sm:hidden">
                <select
                    name="tabs"
                    onChange={handleMobileOnChange}
                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    defaultValue={activeItem?.to}>
                    {items.map((item) => (
                        <option key={item.to}>{item.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {items.map((item) => {
                            const isActiveItem = activeItem?.name === item.name && activeItem?.to === item.to;

                            return (
                                <NavLink
                                    to={item.to}
                                    key={item.name}
                                    className={classnames(
                                        isActiveItem
                                            ? "border-indigo-500 text-indigo-600"
                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm group inline-flex items-center"
                                    )}
                                    aria-current={isActiveItem ? "page" : undefined}>
                                    {item.icon && (
                                        <OutlineIcon
                                            name={item.icon as TOutlineIconName}
                                            aria-hidden="true"
                                            className={classnames(
                                                isActiveItem ? "text-indigo-500" : "text-gray-400 group-hover:text-gray-500",
                                                "-ml-0.5 mr-2 h-5 w-5"
                                            )}
                                        />
                                    )}
                                    {item.name}
                                </NavLink>
                            );
                        })}
                    </nav>
                </div>
            </div>
        </div>
    );
}
