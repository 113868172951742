import React from "react";

interface IErrorMessageProps {
    error: any;
}

export function ErrorMessage({ error }: IErrorMessageProps) {
    return (
        <p className="mt-1 text-xs text-red-500">
            {error}
        </p>
    );
}
