import { hooks as appHooks } from "@app/app";
import { hooks as cartHooks } from "@app/cart";
import React from "react";
import { CartContext } from "../context/CartContext";
import { ICartItem } from "../types";
export interface ICartContextProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

const EMPTY_CART_ITEMS: ICartItem[] = [];
/**
 * CartContextProvider
 * Decorates AuthContext.Provider with loader functionality
 * @param props
 * @returns
 */
export function CartContextProvider({ children }: ICartContextProviderProps) {
    const {
        state: { projectId },
    } = appHooks.useAppState();
    const { data: cartItems = EMPTY_CART_ITEMS } = cartHooks.useFetchCartItems(projectId);

    return <CartContext.Provider value={cartItems}>{children}</CartContext.Provider>;
}
