import React from "react";

export const useForwardedRef = <T extends {}>(ref: React.ForwardedRef<T>): React.MutableRefObject<T | null> => {
    const innerRef = React.useRef(null);

    React.useEffect(() => {
        if (!ref) {
            return;
        }

        if (typeof ref === "function") {
            ref(innerRef.current);
        } else {
            ref.current = innerRef.current;
        }
    });

    return innerRef as React.MutableRefObject<T | null>;
};

export const useTimeout = (callback: () => void, delay = 0) => {
    const timeoutRef: React.MutableRefObject<number | null> = React.useRef(null);
    const savedCallback = React.useRef(callback);

    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    React.useEffect(() => {
        const tick = () => savedCallback.current();

        if (typeof delay === "number") {
            timeoutRef.current = window.setTimeout(tick, delay);

            return () => window.clearTimeout(timeoutRef.current!);
        }
    }, [delay]);
    return timeoutRef;
};
