import { LoadingMask } from "@ui";
import React from "react";
import { Outlet } from "react-router-dom";
import { Sidebar } from "../Sidebar";

export interface IContentWithNavigationSidebarLayoutProps {
    preOutletChildren?: React.ReactNode;
}

export function ContentWithNavigationSidebarLayout({ preOutletChildren }: IContentWithNavigationSidebarLayoutProps) {
    // const [isOpen, setOpen] = useState(false);
    // const { data: navigationItems } = navigationHooks.useGetMainNavigationItems();

    // const handleMobileNavigationToggle = () => {
    //     setOpen((actualIsOpen) => !actualIsOpen);
    // };

    return (
        <React.Fragment>
            {/* <div className="md:hidden lg:hidden absolute right-6 top-6 z-50">
                <button
                    type="button"
                    className="text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500"
                    onClick={handleMobileNavigationToggle}>
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-8 w-8" aria-hidden="true" />
                </button>
            </div>

            {/* Mobile menu */}
            {/* <MobileNavigation items={navigationItems || []} isOpen={isOpen} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} /> */}

            <Sidebar />
            <div className="md:pl-64 bg-slate-50 h-full">
                <div className="max-w-8xl mx-auto flex flex-col md:px-8 xl:px-8 h-full">
                    {preOutletChildren}
                    <React.Suspense fallback={<LoadingMask isLoading={true} />}>
                        <Outlet />
                    </React.Suspense>
                </div>
            </div>
        </React.Fragment>
    );
}
