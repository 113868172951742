import { hooks as authHooks } from "@app/auth";
import { hooks as navigationHooks, MobileNavigation, navigationUtils } from "@app/navigation";
import { LoadingMask } from "@ui";
import React, { useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAppState } from "../../hooks";
import { Footer } from "../Footer";
import { Topbar } from "../Topbar";

export const ContentWithNavigationTopbarLayout = () => {
    const {
        state: { projectId, domain },
    } = useAppState();
    const [isOpen, setOpen] = useState(false);
    const auth = authHooks.useAuth();
    const { data: navigationItems = [] } = navigationHooks.useGetMainNavigationItems();

    const processedNavigationItems = useMemo(
        () => navigationUtils.replaceNavigationItemsPlaceholders(navigationItems, { projectId }),
        [navigationItems, projectId]
    );

    const handleMobileNavigationToggle = () => {
        setOpen((actualIsOpen) => !actualIsOpen);
    };

    return (
        <React.Suspense fallback={<LoadingMask isLoading={true} />}>
            <div className="bg-white h-full">
                {/* Mobile menu */}
                <MobileNavigation
                    items={processedNavigationItems}
                    isOpen={isOpen}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                />

                <Topbar navigationItems={processedNavigationItems} onMobileNavigationToggle={handleMobileNavigationToggle} />

                <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <Outlet />
                </main>

                <Footer
                    address={
                        {
                            street: "Suikerlaan",
                            house_number: "23-B",
                            zip_code: "9743 DA",
                            city: "Groningen",
                            phone_number: "0501234567",
                        } as any
                    }
                    domain={domain}
                />
            </div>
        </React.Suspense>
    );
};
