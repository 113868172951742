import { storageManager } from "@app/app";
import { queries as productQueries, types as productTypes } from "@app/product";
import { set } from "lodash";
import { StorageKeys } from "./constants/StorageKeys";
import { ICartItem } from "./types";

export const getCartItems = async (context?: string | number): Promise<ICartItem[]> =>
    storageManager.getGlobalSession().get<ICartItem[]>(getContextStorageKey(StorageKeys.CartItems, context), []);

export interface IAddproductProps {
    product: productTypes.IProduct;
    count?: number;
}

export const addProduct = async ({ product, count = 1 }: IAddproductProps, context?: string | number) => {
    const currentCartItems = await getCartItems(context);
    const newCartItems = [...currentCartItems];
    const existingIndex = findCartItemIndexByProduct(product, currentCartItems);

    if (existingIndex === -1) {
        newCartItems.push({
            product,
            count,
        });
    } else {
        newCartItems[existingIndex].count += count;
    }

    return setCartItems(newCartItems, context);
};

export const deleteProduct = async (product: productTypes.IProduct, count = 1, context?: string | number) => {
    const currentCartItems = await getCartItems(context);
    const newCartItems = [...currentCartItems];

    const existingIndex = findCartItemIndexByProduct(product, currentCartItems);

    if (existingIndex !== -1) {
        newCartItems[existingIndex].count -= count;

        if (newCartItems[existingIndex].count < 1) {
            newCartItems.splice(existingIndex, 1);
        }
    }

    return setCartItems(newCartItems, context);
};

export const setCartItems = async (items: ICartItem[], context?: string | number) =>
    storageManager.getGlobalSession().set(getContextStorageKey(StorageKeys.CartItems, context), items);

export const deleteCartItems = async (context?: string | number) =>
    storageManager.getGlobalSession().clear(getContextStorageKey(StorageKeys.CartItems, context));

export const syncCartItemsStock = async (context?: string | number) => {
    const cartItems = await getCartItems(context);
    let newCartItems = [...cartItems];
    const productIds = cartItems.map((cartItem) => cartItem.product.id);
    const productStockList = await productQueries.getProductStockList(productIds);

    productStockList.forEach((productStockListItem, index) => {
        const foundIndex = cartItems.findIndex((cartItem) => cartItem.product.id === productStockListItem.id);

        if (foundIndex !== -1) {
            newCartItems = set(newCartItems, `${foundIndex}.product.stock`, productStockListItem.stock);
        }
    });

    return setCartItems(newCartItems, context);
};

export const setHasAddedToCartDialogBeenShown = async (hasBeenShown: boolean, context?: string | number) =>
    storageManager.getGlobalSession().set(getContextStorageKey(StorageKeys.HasAddedToCartDialogBeenShown, context), hasBeenShown);

export const getHasAddedToCartDialogBeenShown = async (context?: string | number) =>
    storageManager.getGlobalSession().get(getContextStorageKey(StorageKeys.HasAddedToCartDialogBeenShown, context));

/**
 * private
 */

export const findCartItemIndexByProduct = (product: productTypes.IProduct, cartItems: ICartItem[]) =>
    cartItems.findIndex((cartItem) => cartItem.product.id === product.id);

/**
 * getContextStorageKey
 */
const getContextStorageKey = (key: string, context?: string | number) => {
    const r = !!context ? `${context}_${key}` : key;
    console.log("getContextStorageKey", r);
    // eslint-disable-next-line no-debugger
    // debugger;
    return r;
};
