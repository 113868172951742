// import { hooks as appHooks } from "@app/app";
import { StorageKeys as AppStorageKeys, storageManager } from "@app/app";
import { hooks as domainHooks } from "@app/domain";
import { axios } from "@app/services";
import { EyeIcon, EyeOffIcon, LockClosedIcon, MailIcon } from "@heroicons/react/outline";
import { Button, ButtonVariants } from "@ui";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { config } from "../config";
import { useLogin } from "../hooks";
import { ILoginProps } from "../queries";

export function LoginPage() {
    const { register, handleSubmit } = useForm<ILoginProps>();
    const [error, setError] = React.useState<string | null>(null);
    const setErrorRef = useRef(setError);
    const { t } = useTranslation("auth");
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const login = useLogin();
    const hostname = window.location.hostname;
    const { data: domainPublic } = domainHooks.useGetPublicDomainItem(hostname, { enabled: !!hostname });
    // const {
    //     state: { domainPublic },
    // } = appHooks.useAppState();
    const domainAvatar = domainPublic?.avatar;
    const domainWelcomeImage = domainPublic?.welcome_image;

    setErrorRef.current = setError;

    useEffect(() => {
        // make sure app state storage is cleared before login
        storageManager.getGlobalSession().set(AppStorageKeys.AppState, {});
    }, []);

    function onSubmit(props: ILoginProps) {
        axios.get(config.sanctumUrl).then(() => {
            login.mutate(props, {
                onSuccess: (authData) => {
                    navigate(`/projects/${authData.defaultProjectId || 0}/catalog`);
                },
                onError: (loginError) => {
                    if ([401, 422].includes(loginError.response?.status || 0)) {
                        setErrorRef.current?.(t("page.login.form.field.validate-invalid-login"));
                    }
                },
            });
        });
    }

    const togglePassword = () => {
        setShowPassword((prevState) => !prevState);
    };

    return (
        <div className="min-h-full flex">
            <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        {/* {domainAvatar && <img src="/concept/style26.svg" width={100} alt="" />} */}
                        {domainAvatar && <img src={domainAvatar.original_url} width={100} alt="" />}
                        <h2 className="mt-6 text-2xl text-gray-900 font-heading">{t("page.login.header.title")}</h2>
                    </div>

                    <div className="mt-8">
                        <div className="mt-6">
                            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                                <input {...register("hostname")} id="hostname" name="hostname" type="hidden" value={hostname} />
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        {t("page.login.form.field.label-email")}
                                    </label>
                                    <div className="mt-1 relative rounded-md">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                        <input
                                            {...register("email")}
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            placeholder={t("page.login.form.field.placeholder-email")}
                                            className="appearance-none block w-full pl-10 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        {t("page.login.form.field.label-password")}
                                    </label>
                                    <div className="mt-1 relative rounded-md">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                        <input
                                            {...register("password")}
                                            id="password"
                                            name="password"
                                            type={showPassword ? "text" : "password"}
                                            autoComplete="current-password"
                                            required
                                            placeholder={t("page.login.form.field.placeholder-password")}
                                            className="appearance-none block w-full pl-10 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                        <div className="absolute inset-y-0 right-0 pr-3 text-gray-500 sm:text-sm flex items-center">
                                            {showPassword ? (
                                                <EyeOffIcon className="h-5 w-5 text-gray-400 cursor-pointer" onClick={togglePassword} />
                                            ) : (
                                                <EyeIcon className="h-5 w-5 text-gray-400 cursor-pointer" onClick={togglePassword} />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <input
                                            id="remember-me"
                                            name="remember-me"
                                            type="checkbox"
                                            className="h-4 w-4 text-hc_green-700 focus:text-hc_green-900 border-gray-300 rounded"
                                        />
                                        <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                            {t("page.login.form.field.label-remember-me")}
                                        </label>
                                    </div>
                                </div>
                                {error && <div className="text-sm text-red-700">{error}</div>}
                                <Button as="button" variant={ButtonVariants.Primary} className="w-full justify-center">
                                    {t("page.login.form.field.button-label-sign-in")}
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1">
                {domainWelcomeImage ? (
                    <img className="absolute inset-0 h-full w-full object-cover" src={domainWelcomeImage.original_url} alt="" />
                ) : (
                    <img className="absolute inset-0 h-full w-full object-cover" src="/concept/style26.png" alt="" />
                )}
            </div>
        </div>
    );
}
