import { types as orderTypes } from "@app/order";
import { types as productTypes } from "@app/product";
import { axios } from "@app/services";
import { types as userTypes } from "@app/user";
import { IProject, IProjectProduct } from "./types";

/**
 * projects
 */

export const getProjectItem = async (id: number): Promise<IProject> => {
    const response = await axios.get<{ data: IProject }>(`/projects/${id}`);

    return response.data.data;
};

export const createProjectItem = async (project: IProject): Promise<IProject> => {
    const response = await axios.post<{ data: IProject }>(`/domains/${project.domain_id}/projects`, project);

    return response.data.data;
};

export const updateProjectItem = async (project: IProject): Promise<IProject> => {
    const response = await axios.put<{ data: IProject }>(`/projects/${project.id}`, project);

    return response.data.data;
};

/**
 * projects/products
 */

export const getProjectProductItem = async (projectId: number, productId: number): Promise<IProjectProduct> => {
    const response = await axios.get<{ data: IProjectProduct }>(`/projects/${projectId}/products/${productId}`);

    return response.data.data;
};

export interface IGetProjectProductItemsAdditionalParams {
    excludeProductVariants?: boolean;
}

export const getProjectProductItems = async (
    projectId: number,
    page: number,
    search?: string,
    additionalParams?: IGetProjectProductItemsAdditionalParams
): Promise<productTypes.IProductResponse> => {
    const response = await axios.get<productTypes.IProductResponse>(`projects/${projectId}/products`, {
        params: { page, search, exclude_product_variants: additionalParams?.excludeProductVariants },
    });

    return response.data;
};

export const updateProjectProductItem = async (projectId: number, item: productTypes.IProduct): Promise<productTypes.IProduct> => {
    const response = await axios.put(`/projects/${projectId}/products/${item.id}`, item);

    return response.data;
};

/**
 * project/users
 */

export const createProjectUserItem = async (projectId: number, item: userTypes.IUser): Promise<userTypes.IUser> => {
    const response = await axios.post(`/projects/${projectId}/users`, item);

    return response.data;
};

export const getProjectUserItems = async (id: number, page: number, search?: string): Promise<userTypes.IUserItemsResponse> => {
    const response = await axios.get<userTypes.IUserItemsResponse>(`projects/${id}/users`, { params: { page, search } });

    return response.data;
};

export const inviteUsersToProject = async (formData: { project_id: number; emails: [] }) => {
    const response = await axios.post(`projects/${formData.project_id}/users`, { emails: formData.emails });

    return response.data;
};

/**
 * projects/orders
 */

export const getProjectOrders = async (projectId: number, page: number, search: string | null): Promise<orderTypes.IOrderResponse> => {
    const response = await axios.get<orderTypes.IOrderResponse>(`projects/${projectId}/orders`, {
        params: { page, search },
    });

    return response.data;
};

export const createProjectOrderItem = async (projectId: number, item: orderTypes.IOrder): Promise<orderTypes.IOrder> => {
    /**
     * FIX projectID @santal
     */
    const response = await axios.post(`/projects/${item?.project?.id}/orders`, item);

    return response.data;
};
