import { types as domainTypes } from "@app/domain";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/outline";
import { Avatar } from "@ui";
import { Fragment } from "react";

interface IDomainSwitcher {
    onChange: (id: number) => void;
    activeDomain?: domainTypes.IDomain;
    domains: domainTypes.IDomain[];
}

export const DomainSwitcher = ({ domains, activeDomain, onChange }: IDomainSwitcher) => {
    const hasMultipleDomains = domains.length > 1;
    const ButtonComponent = hasMultipleDomains ? Listbox.Button : "button";

    return (
        <Listbox value={activeDomain?.id} onChange={onChange}>
            <div>
                <ButtonComponent
                    className="group w-full  border-t border-b py-2 text-sm text-left font-medium text-gray-700"
                    disabled={!hasMultipleDomains}>
                    <span className="flex w-full justify-between items-center">
                        <span className="flex min-w-0 items-center justify-between space-x-3">
                            <Avatar url={activeDomain?.avatar?.original_url} fallBackInitials={activeDomain?.company || ""} />
                            <span className="flex-1 flex flex-col min-w-0">
                                <span className="text-gray-800 text-sm font-medium truncate">{activeDomain?.company}</span>
                                <span className="text-gray-500 text-xs font-light truncate">{activeDomain?.url}</span>
                            </span>
                        </span>
                        {hasMultipleDomains && (
                            <SelectorIcon className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                        )}
                    </span>
                </ButtonComponent>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Listbox.Options className="z-10 origin-top absolute top-34 mt-1 right-0 left-0 rounded-md mx-2 shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                    {domains
                        .filter((domain) => domain.id !== activeDomain?.id)
                        .map((domain) => (
                            <Listbox.Option key={domain.id} value={domain.id}>
                                <span className="flex w-full justify-between items-center px-2 py-3 cursor-pointer hover:bg-slate-100">
                                    <span className="flex min-w-0 items-center justify-between space-x-3">
                                        <Avatar url={domain.avatar?.original_url} fallBackInitials={domain.company} />
                                        <span className="flex-1 flex flex-col min-w-0">
                                            <span className="text-gray-800 text-sm font-medium truncate">{domain.company}</span>
                                            <span className="text-gray-500 text-xs font-light truncate">{domain.url}</span>
                                        </span>
                                    </span>
                                </span>
                            </Listbox.Option>
                        ))}
                </Listbox.Options>
            </Transition>
        </Listbox>
    );
};
