import { types as branchTypes } from "@app/branch";
import { requestUtils } from "@app/common";
import { types as projectTypes } from "@app/project";
import { axios } from "@app/services";
import { IClient, IClientItemsResponse } from "./types";

/**
 * clients
 */

export const getClientItem = async (id: number): Promise<IClient> => {
    const response = await axios.get<{ data: IClient }>(`clients/${id}`);

    return response.data?.data;
};

export const getClientItems = async (page: number, search?: string): Promise<IClientItemsResponse> => {
    const response = await axios.get<IClientItemsResponse>("clients", { params: { page, search } });

    return response.data;
};

export const updateClientItem = async (item: IClient): Promise<IClient> => {
    const formData = requestUtils.constructFormData(item, ["avatar"], "PUT");

    const response = await axios.post(`clients/${item.id}`, formData, {
        headers: {
            "content-type": "multipart/form-data",
        },
    });

    return response.data;
};

export const createClientItem = async (item: IClient): Promise<IClient> => {
    const formData = requestUtils.constructFormData(item, ["avatar"]);

    const response = await axios.post("clients", formData, {
        headers: {
            "content-type": "multipart/form-data",
        },
    });

    return response.data;
};

export const deleteClientItem = async (clientId: number) => {
    const response = await axios.delete(`clients/${clientId}`);

    return response.data;
};

/**
 * clients/branches
 */

export const getClientBranchItems = async (clientId: number, page: number, search?: string): Promise<branchTypes.IBranchItemsResponse> => {
    const response = await axios.get<branchTypes.IBranchItemsResponse>(`clients/${clientId}/branches`, {
        params: { page, search },
    });

    return response.data;
};

export const createClientBranchItem = async (clientId: number, item: branchTypes.IBranch): Promise<branchTypes.IBranch> => {
    const formData = requestUtils.constructFormData(item, ["avatar"]);

    const response = await axios.post(`/clients/${clientId}/branches`, formData, {
        headers: {
            "content-type": "multipart/form-data",
        },
    });

    return response.data;
};

/**
 * clients/projects
 */

export const createClientProjectItem = async (clientId: number, project: projectTypes.IProject): Promise<projectTypes.IProject> => {
    const response = await axios.post<{ data: projectTypes.IProject }>(`/clients/${clientId}/projects`, project);

    return response.data.data;
};

export const getClientProjectItems = async (clientId: number, page: number, search?: string): Promise<projectTypes.IProject[]> => {
    const response = await axios.get<{ data: projectTypes.IProject[] }>(`/clients/${clientId}/projects`, {
        params: { page, search },
    });

    return response.data.data;
};
