import React from "react";

interface ICardHeaderProps {
    title?: string;
    description?: string;
    children?: any;
    className?: string;
}

export function CardHeader({ children, title, description, className }: ICardHeaderProps) {
    return (
        <div className={className ? className : "px-4 py-5 sm:px-6 flex items-center justify-between border-b"}>
            <div>
                {title && <h3 className="text-xl leading-2 text-gray-700">{title}</h3>}
                {description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
            </div>

            {children}
        </div>
    );
}
