import React from "react";

interface ICardFooterProps {
    children: any;
}

export function CardFooter({ children }: ICardFooterProps) {
    return (
        <div className="px-4 py-4 sm:px-6 flex flex-row-reverse">
            {children}
        </div>
    );
}
