import classnames from "classnames";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

export interface IButtonProps {
    [key: string]: any;
    children: React.ReactNode;
    variant?: Variants;
    size?: Sizes;
    as?: string | React.FC;
    className?: string;
    isLoading?: boolean;
    onClick?: (event: React.MouseEvent<any>) => void;
    disabled?: boolean;
    to?: string | null;
}

export enum Variants {
    Primary = "primary",
    PrimaryOutline = "primary-outline",
    Default = "default",
    DefaultOutline = "default-outline",
    Danger = "danger",
    DangerOutline = "danger-outline",
    Transparent = "transparent",
}

export enum Sizes {
    Large = "large",
    Medium = "medium",
    Small = "small",
}

export const Button = React.forwardRef(
    (
        { children, variant, as = "a", className, isLoading, size, onClick, disabled, to = null, ...additionalProps }: IButtonProps,
        ref: any
    ) => {
        const Component: any = to ? "span" : as;
        const buttonColor = (): string => {
            switch (variant) {
                case Variants.Danger:
                    return "bg-fp_red-500 border border-fp_red-500 text-white hover:opacity-80 text-center";
                case Variants.DangerOutline:
                    return "bg-white text-fp_red-500 border border-fp_red-500 hover:opacity-80 text-center";
                case Variants.Transparent:
                    return "rounded-md text-sm leading-4 text-slate-700 shadow-none";
                case Variants.Primary:
                    return "text-white bg-indigo-600 border border-indigo-600 hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-700";
                case Variants.PrimaryOutline:
                    return "bg-white text-indigo-600 border border-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-700";
                // case Variants.DefaultOutline:
                //     return "bg-white border border-slate-300 rounded-md shadow-sm text-sm  font-medium text-slate-700 hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
                case Variants.Default:
                default:
                    return "bg-white border border-slate-300 rounded-md shadow-sm text-sm font-medium text-slate-700 hover:bg-slate-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500";
            }
        };
        const buttonSize = (): string => {
            switch (size) {
                case Sizes.Small:
                    return "py-1 px-2 font-sm text-xs leading-4 font-medium";
                case Sizes.Large:
                    return "px-6 py-4 rounded-lg";
                case Sizes.Medium:
                default:
                    return "px-4 py-2 text-sm";
            }
        };

        const button = () => (
            <Component
                {...additionalProps}
                ref={ref}
                disabled={disabled}
                onClick={!disabled ? onClick : null}
                className={classnames(
                    "inline-flex cursor-pointer rounded text-center items-center shadow-sm relative",
                    buttonColor(),
                    buttonSize(),
                    className
                )}>
                {children}
            </Component>
        );

        return to ? <RouterLink to={to}>{button()}</RouterLink> : button();
    }
);
