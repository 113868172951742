import { requestUtils } from "@app/common";
import { axios } from "@app/services";
import { IUser, IUserItemsResponse } from "./types";

/**
 * users
 */

export const getUserItem = async (userId: number): Promise<IUser> => {
    const response = await axios.get<{ data: IUser }>(`users/${userId}`);

    return response.data?.data;
};

export const getUserItems = async (page: number, search?: string): Promise<IUserItemsResponse> => {
    const response = await axios.get<IUserItemsResponse>("users", { params: { page, search } });

    return response.data;
};

export const updateUserItem = async (item: IUser): Promise<IUser> => {
    const formData = requestUtils.constructFormData(item, ["avatar"], "PUT");

    // React.ChangeEvent<HTMLInputElement>

    const response = await axios.post(`users/${item.id}`, formData, {
        headers: {
            "content-type": "multipart/form-data",
        },
    });

    return response.data;
};

export const createUserItem = async (item: IUser): Promise<IUser> => {
    const formData = requestUtils.constructFormData(item, ["avatar"]);

    const response = await axios.post("users", formData, {
        headers: {
            "content-type": "multipart/form-data",
        },
    });

    return response.data;
};

export const deleteUserItem = async (userId: number) => {
    const response = await axios.delete(`users/${userId}`);

    return response.data;
};
