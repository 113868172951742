import classnames from "classnames";
import React from "react";

interface SubHeaderTitleProps {
    title: string;
    description: string;
    className?: string;
}

export function SubHeaderTitle({ title, description, className }: SubHeaderTitleProps) {
    return (
        <div className={classnames("mb-4", className)}>
            <h3 className="text-lg leading-6 text-slate-900">{title}</h3>
            {description && <p className="mt-1 text-sm text-slate-500">{description}</p>}
        </div>
    );
}
