import { types as domainTypes } from "@app/domain";
import React from "react";

export interface IFooterDomainAddressProps {
    domain: domainTypes.IDomain;
}

export const FooterCopyright = ({ domain }: IFooterDomainAddressProps) => (
    <p className="mt-6 text-sm text-gray-500 text-center sm:mt-0">&copy; 2022 {domain.company}</p>
);
