import { hooks as appHooks } from "@app/app";
import { useTranslation } from "react-i18next";
import { useDeleteProduct } from "../hooks";
import { ICartItem } from "../types";
import { CartBarItemListItem } from "./CartBarItemListItem";

export interface ICartBarItemListProps {
    items: ICartItem[] | undefined;
    onRemoveItem?: (item: ICartItem) => void;
    onClickItem?: (item: ICartItem) => void;
    cartItemUriResolver: (item: ICartItem) => string;
}
export const CartBarItemList = ({ items, cartItemUriResolver, onClickItem }: ICartBarItemListProps) => {
    const hasItems = !!items?.length;
    const {
        state: { projectId },
    } = appHooks.useAppState();

    const deleteProductMutator = useDeleteProduct(projectId);
    const { t } = useTranslation("cart");

    const handleDeleteItem = (item: ICartItem) => {
        deleteProductMutator.mutate(item.product);
    };

    return (
        <ul role="list" className="divide-y divide-gray-200 min-w-[14em]">
            {!hasItems ? (
                <li className="py-4 flex items-center">{t("bar-item.text-no-items")}</li>
            ) : (
                (items || []).map((item, index) => (
                    <CartBarItemListItem
                        key={item.product.id}
                        item={item}
                        onRemoveItem={handleDeleteItem}
                        onClickItem={onClickItem}
                        cartItemUriResolver={cartItemUriResolver}
                    />
                ))
            )}
        </ul>
    );
};
