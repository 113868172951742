import { i18next } from "@app/services";
import { Dialog, Transition } from "@headlessui/react";
import { Button, ButtonVariants, Card, CardContent, CardFooter, CardHeader } from "@ui";
import React, { useState } from "react";

export interface IErrorDialogProps {
    error: Error;
    onCancel?: () => void;
    onRetry?: () => void;
}

export const ErrorDialog = ({ error, onCancel, onRetry }: IErrorDialogProps) => {
    const [isOpen, setIsOpen] = useState(true);
    const t = i18next.getFixedT("common");

    return (
        <React.Fragment>
            {/* <Button variant={ButtonVariants.Primary} onClick={() => setIsOpen(true)}>
            Add/Change
        </Button> */}

            <Transition
                show={isOpen}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0">
                <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex items-center justify-center p-4">
                        <Card className="">
                            <CardHeader
                                title={t("common:error-dialog.title")}
                                description={t("common:error-dialog.description")}></CardHeader>
                            <CardContent cardFluid={true} className="bg-white overflow-y-scroll h-80 w-96" style={{}}>
                                <div role="alert" className="p-6">
                                    <p className="mb-6">{t("common:error-dialog.text-content")}:</p>

                                    <pre>{error.message}</pre>
                                </div>
                            </CardContent>
                            <CardFooter>
                                {onRetry && (
                                    <Button
                                        onClick={() => {
                                            setIsOpen(false);

                                            if (onRetry) {
                                                onRetry();
                                            }
                                        }}
                                        variant={ButtonVariants.Primary}
                                        className="ml-2">
                                        {t("common:error-dialog.button-try-again")}
                                    </Button>
                                )}
                                {onCancel && <Button onClick={() => setIsOpen(false)}>{t("common:error-dialog.button-cancel")}</Button>}
                            </CardFooter>
                        </Card>
                    </div>
                </Dialog>
            </Transition>
        </React.Fragment>
    );
};
