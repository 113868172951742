import { types as branchTypes } from "@app/branch";
import { types as commonTypes } from "@app/common";
import { types as projectTypes } from "@app/project";
import { queryClient } from "@app/services";
import { useMutation, useQuery } from "react-query";
import { QueryKeys } from "./constants/QueryKeys";
import {
    createClientBranchItem,
    createClientItem,
    createClientProjectItem,
    getClientBranchItems,
    getClientItem,
    getClientItems,
    getClientProjectItems,
    updateClientItem,
} from "./queries";
import { IClient, IClientItemsResponse } from "./types";

/**
 * clients
 */

export const useGetClientItem = (id: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IClient, Error>([QueryKeys.ClientItem, id], () => getClientItem(id), options);

export const useUpdateClientItem = () =>
    useMutation(updateClientItem, {
        onSuccess: (updatedItem: IClient, postedItem) => {
            queryClient.invalidateQueries([QueryKeys.ClientItem, postedItem.id]);

            // queryClient.invalidateQueries({
            //     predicate: (query) => query.queryHash.includes("client-item") || query.queryHash.includes("auth"),
            // });
        },
    });

export const useCreateClientItem = () => useMutation(createClientItem);

export const useGetClientItems = (page: number, search?: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<IClientItemsResponse, Error>([QueryKeys.ClientItems, { page, search }], () => getClientItems(page, search), options);

/**
 * clients/branches
 */

export const useGetClientBranchItems = (clientId: number, page: number, search?: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<branchTypes.IBranchItemsResponse, Error>(
        [QueryKeys.ClientBranchItems, { clientId, page, search }],
        () => getClientBranchItems(clientId, page, search),
        options
    );

export const useCreateClientBranchItem = (clientId: number) =>
    useMutation((item: branchTypes.IBranch) => createClientBranchItem(clientId, item));

/**
 * clients/projects
 */

export const useGetClientProjectItems = (clientId: number, page: number, search?: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<projectTypes.IProject[], Error>(
        [QueryKeys.ClientProjectItems, { clientId }],
        () => getClientProjectItems(clientId, page, search),
        options
    );

export const useCreateClientProjectItem = (clientId: number) =>
    useMutation((item: projectTypes.IProject) => createClientProjectItem(clientId, item));
