import { ControlledFormField } from "@app/common";
import { Button, ButtonVariants, Form, Input, LoadingMask } from "@ui";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useGetConfirmation, useUpdateConfirmation } from "../hooks";

export const ConfirmationPage = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { data, isLoading } = useGetConfirmation(params.token || "");
    const { mutateAsync } = useUpdateConfirmation();
    const { control, handleSubmit, reset } = useForm();

    const submit = (formData: any) => {
        mutateAsync(formData).then(() => navigate("/"));
    };

    useEffect(() => reset(data), [data, reset]);

    return (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="w-full flex justify-center">
                    <img className="h-20 w-auto " src="/concept/style26.svg" alt="" />
                </div>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Complete your registration</h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <LoadingMask isLoading={isLoading}>
                    {data?.email ? (
                        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                            <Form onSubmit={handleSubmit(submit)}>
                                <ControlledFormField
                                    control={control}
                                    name="token"
                                    formFieldClassName="col-span-4"
                                    component={Input}
                                    disabled={true}
                                    type="hidden"
                                    value={"ab"}
                                    label=""
                                    rules={{
                                        validate: {
                                            validName: (value: any) => value !== "invalid" || "Invalid name",
                                        },
                                    }}
                                />

                                <ControlledFormField
                                    control={control}
                                    name="email"
                                    formFieldClassName="col-span-4"
                                    component={Input}
                                    disabled={true}
                                    value={"ab"}
                                    label="Email"
                                    rules={{
                                        validate: {
                                            validName: (value: any) => value !== "invalid" || "Invalid name",
                                        },
                                    }}
                                />

                                <ControlledFormField
                                    control={control}
                                    name="name"
                                    formFieldClassName="col-span-4"
                                    component={Input}
                                    label="Name"
                                    rules={{
                                        validate: {
                                            validName: (value: any) => value !== "invalid" || "Invalid name",
                                        },
                                    }}
                                />

                                <ControlledFormField
                                    control={control}
                                    name="password"
                                    formFieldClassName="col-span-4"
                                    component={Input}
                                    type="password"
                                    label="Password"
                                    rules={{
                                        validate: {
                                            validName: (value: any) => value !== "invalid" || "Invalid name",
                                        },
                                    }}
                                />

                                <ControlledFormField
                                    control={control}
                                    name="password2"
                                    type="password"
                                    formFieldClassName="col-span-4"
                                    component={Input}
                                    label="Controle password"
                                    rules={{
                                        validate: {
                                            validName: (value: any) => value !== "invalid" || "Invalid name",
                                        },
                                    }}
                                />

                                <Button variant={ButtonVariants.Primary} as="button" className="col-span-4 flex justify-center">
                                    Register
                                </Button>
                            </Form>
                        </div>
                    ) : (
                        <div className="text-center w-full">Not a valid token</div>
                    )}
                </LoadingMask>
            </div>
        </div>
    );
};
