import { hooks as appHooks } from "@app/app";
import { types as productTypes } from "@app/product";
import { XIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ICartItem } from "../types";

export interface ICartBarItemListItemProps {
    item: ICartItem;
    onRemoveItem?: (item: ICartItem) => void;
    onClickItem?: (item: ICartItem) => void;
    cartItemUriResolver: (item: ICartItem) => string;
}

export const CartBarItemListItem = ({ item, onRemoveItem, cartItemUriResolver, onClickItem }: ICartBarItemListItemProps) => {
    const {
        state: { project },
    } = appHooks.useAppState();

    const { product, count } = item;
    const hasFirstMedia = !!product?.media[0];
    const firstMedia = hasFirstMedia ? (product.media[0] as productTypes.IProductImage) : null;
    const firstMediaSrc = hasFirstMedia ? firstMedia?.preview_url : "https://dummyimage.com/600x400/f8fafc/ffffff?text=%20";
    const isInStock = project?.can_order_out_of_stock || (!!product.stock && product.stock >= count);

    const cartItemUri = cartItemUriResolver(item);
    const isProductVariation = !!product.variant_parent_id;

    const handleRemoveItemClick = () => {
        if (onRemoveItem) {
            onRemoveItem(item);
        }
    };

    return (
        <li key={product.id} className="py-4 last:py-0 flex">
            <img
                src={firstMediaSrc}
                alt={product.name}
                className={classNames("flex-none w-16 h-16 rounded-md border border-gray-200", {
                    "opacity-40": !isInStock,
                })}
            />
            <div className="ml-4 flex-auto">
                <h3 className="text-gray-700 hover:text-gray-900">
                    <XIcon
                        className="block h-4 w-4 cursor-pointer absolute right-6 text-gray-400 hover:text-indigo-700"
                        aria-hidden="true"
                        onClick={handleRemoveItemClick}
                    />
                    <Link
                        to={cartItemUri}
                        onClick={() => onClickItem && onClickItem(item)}
                        className={classNames({
                            "line-through opacity-40": !isInStock,
                        })}>
                        {count} x {product.name}
                    </Link>
                </h3>
                {isProductVariation && (
                    <ul className="text-xs">
                        <li>{product.variant_size}</li>
                        <li>{product.variant_color}</li>
                    </ul>
                )}
            </div>
        </li>
    );
};
