import React from "react";
import { IAddress } from "../types";

export interface IAddressLineProps {
    address: IAddress;
}

export const AddressLine = ({ address }: IAddressLineProps) => (
    <React.Fragment>
        {address.street} {address.house_number}
        {address.house_number_extended ? `-${address.house_number_extended}` : ""}, {address.zip_code} {address.city}
    </React.Fragment>
);
