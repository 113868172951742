import { utilsQuery } from "@app/common";

const define = utilsQuery.defineQueryKeyCreator("project");

export const QueryKeys = {
    ProjectItems: define("project-items"),
    ProjectItem: define("project-item"),
    ProjectProductItem: define("project-product-item"),
    ProjectProductItems: define("project-product-items"),
    ProjectUserItems: define("project-user-items"),
    ProjectOrderItems: define("project-order-items"),
} as const;
