import { utilsQuery } from "@app/common";

const define = utilsQuery.defineQueryKeyCreator("branch");

export const QueryKeys = {
    ClientItems: define("client-items"),
    ClientItem: define("client-item"),
    ClientBranchItems: define("client-branch-items"),
    ClientProjectItems: define("client-project-items"),
} as const;
