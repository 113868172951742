import { queries as clientQueries, types as clientTypes } from "@app/client";
import { types as commonTypes } from "@app/common";
import { types as orderTypes } from "@app/order";
import { types as paymentTypes } from "@app/paymtent";
import { queries as productQueries, types as productTypes } from "@app/product";
import { queries as productSectionQueries, types as productSectionTypes } from "@app/productSection";
import { types as projectTypes } from "@app/project";
import { queryClient } from "@app/services";
import { queries as userQueries, types as userTypes } from "@app/user";
import { useMutation, useQuery } from "react-query";
import { QueryKeys } from "./constants/QueryKeys";
import {
    createDomainClientItem,
    createDomainItem,
    createDomainProductItem,
    createDomainProductSectionItem,
    createDomainProjectItem,
    createDomainUserItem,
    deleteDomainItem,
    getDomainClientItems,
    getDomainItem,
    getDomainItems,
    getDomainOrders,
    getDomainPaymentItem,
    getDomainProductItems,
    getDomainProductSectionItems,
    getDomainProjectItems,
    getDomainUserItems,
    getPublicDomainItem,
    updateDomainItem,
} from "./queries";
import { IDomain, IDomainItemsResponse, IDomainPublic } from "./types";

/**
 * domains
 */
export const useGetDomainItems = (page: number, search?: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<IDomainItemsResponse, Error>([QueryKeys.DomainItems, { page, search }], () => getDomainItems(page, search), options);

export const useGetDomainItem = (id: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IDomain, Error>([QueryKeys.DomainItem, id], () => getDomainItem(id), options);

export const useGetPublicDomainItem = (hostname: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<IDomainPublic, Error>([QueryKeys.DomainPublicItem, hostname], () => getPublicDomainItem(hostname), options);

export const useUpdateDomainItem = () =>
    useMutation(updateDomainItem, {
        onSuccess: (updatedDomain: IDomain, postedDomain: IDomain) => {
            queryClient.invalidateQueries([QueryKeys.DomainItem, postedDomain.id]);
        },
    });

export const useCreateDomainItem = () => useMutation(createDomainItem);

export const useDeleteDomainItem = () =>
    useMutation(deleteDomainItem, {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.DomainItems]);
        },
    });

/**
 * domains/products
 */

export const useGetDomainProductItems = (domainId: number, page: number, search?: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<productTypes.IProductResponse, Error>(
        [QueryKeys.DomainProductItems, { domainId, page, search }],
        () => getDomainProductItems(domainId, page, search),
        options
    );

export const useCreateDomainProductItem = (domainId: number) =>
    useMutation((item: productTypes.IProduct) => createDomainProductItem(domainId, item));

export const useDeleteDomainProductItem = () =>
    useMutation(productQueries.deleteProductItem, {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.DomainProductItems]);
        },
    });

/**
 * domains/projects
 */

export const useGetDomainProjectItems = (domainId: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<projectTypes.IProject[], Error>([QueryKeys.DomainProjectItems, { domainId }], () => getDomainProjectItems(domainId), options);

export const useCreateDomainProjectItem = () => useMutation(createDomainProjectItem);

/**
 * domains/users
 */

export const useGetDomainUserItems = (domainId: number, page: number, search?: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<userTypes.IUserItemsResponse, Error>(
        [QueryKeys.DomainUserItems, { domainId, page, search }],
        () => getDomainUserItems(domainId, page, search),
        options
    );

export const useCreateDomainUserItem = (domainId: number) => useMutation((item: userTypes.IUser) => createDomainUserItem(domainId, item));

export const useDeleteDomainUserItem = () =>
    useMutation(userQueries.deleteUserItem, {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.DomainUserItems]);
        },
    });

/**
 * domains/orders
 */

export const useGetDomainOrders = (domainId: number, page: number, search?: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<orderTypes.IOrderResponse, Error>(
        [QueryKeys.DomainOrderItems, { domainId, page, search }],
        () => getDomainOrders(domainId, page, search),
        options
    );

/**
 * domains/clients
 */

export const useGetDomainClientItems = (domainId: number, page: number, search?: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<clientTypes.IClientItemsResponse, Error>(
        [QueryKeys.DomainClientItems, { domainId, page, search }],
        () => getDomainClientItems(domainId, page, search),
        options
    );

export const useCreateDomainClientItem = (domainId: number) =>
    useMutation((item: clientTypes.IClient) => createDomainClientItem(domainId, item));

export const useDeleteDomainClientItem = () =>
    useMutation(clientQueries.deleteClientItem, {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.DomainClientItems]);
        },
    });

export const useGetDomainPaymentItem = (domainId: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<paymentTypes.IPayment, Error>(QueryKeys.DomainPaymentItem, () => getDomainPaymentItem(domainId), options);

/**
 * domains/productSections
 */

export const useGetDomainProductSectionItems = (domainId: number, page: number, search?: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<productSectionTypes.IProductSectionItemsResponse, Error>(
        [QueryKeys.DomainProductSectionItems, { domainId, page, search }],
        () => getDomainProductSectionItems(domainId, page, search),
        options
    );

export const useCreateDomainProductSectionItem = (domainId: number) =>
    useMutation((item: productSectionTypes.IProductSection) => createDomainProductSectionItem(domainId, item));

export const useDeleteDomainProductSectionItem = () =>
    useMutation(productSectionQueries.deleteProductSectionItem, {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.DomainProductSectionItems]);
        },
    });
