import { PageTitle } from "@app/admin";
import { ContentWithNavigationSidebarLayout } from "@app/app";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetClientItem } from "../../hooks";
import { ClientAdminTabs } from "../ClientAdminTabs";

export const ClientTabContentWithNavigationSidebarLayout = () => (
    <ContentWithNavigationSidebarLayout preOutletChildren={<ProjectAdminContentHeader />} />
);

const ProjectAdminContentHeader = () => {
    const { clientId: rawClientId } = useParams<string>();
    const clientId = Number(rawClientId);
    const { data: client } = useGetClientItem(clientId, { enabled: !!clientId });

    return (
        <React.Fragment>
            <PageTitle title={client?.name || ""} className="mb-0" />
            <ClientAdminTabs client={client} />
        </React.Fragment>
    );
};
