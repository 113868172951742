import { UtilsStr } from "@hotelchamp/common";
import { INavigationItem } from "../types";

export interface IGenericPlaceholderData {
    [key: string]: any;
}

export const replaceNavigationItemsPlaceholders = (navigationItems: INavigationItem[], data: IGenericPlaceholderData): INavigationItem[] =>
    navigationItems.map((navigationItem) => ({
        ...navigationItem,
        to: navigationItem.to ? UtilsStr.supplant(navigationItem.to, data) : undefined,
        items: navigationItem.items ? replaceNavigationItemsPlaceholders(navigationItem.items, data) : navigationItem.items,
    }));
