import { StorageKeys as AppStorageKeys, storageManager } from "@app/app";
import { hooks as authHooks } from "@app/auth";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, LogoutIcon as LogoutIconOutline, UserIcon as UserIconOutline } from "@heroicons/react/outline";
import { LogoutIcon as LogoutIconSolid, UserIcon as UserIconSolid } from "@heroicons/react/solid";
import { Avatar, AvatarSizes } from "@ui";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { IUser } from "../types";

export interface IUserActionsDropdownProps {
    user: IUser;
}

export const UserActionsDropdown = ({ user }: IUserActionsDropdownProps) => {
    const { mutateAsync: logoutMutator, isLoading: isLoggingOut } = authHooks.useLogout();
    const navigate = useNavigate();
    const { t } = useTranslation("user");

    const handleLogout = async () => {
        if (!isLoggingOut) {
            await logoutMutator();

            storageManager.getGlobalSession().set(AppStorageKeys.AppState, {});

            navigate("/");
        }
    };

    return (
        <Menu>
            <Menu.Button>
                <div className="flex gap-2 items-center hover:text-gray-500 px-2 py-1 rounded-md flex text-gray-400 hover:bg-gray-100 hover:text-gray-500">
                    <Avatar url={user.avatar?.original_url} fallBackInitials={user.name} size={AvatarSizes.Small} />
                    <ChevronDownIcon className="h-4 w-4 flex-shrink-0" aria-hidden="true" />
                </div>
            </Menu.Button>
            <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0">
                <Menu.Items className="absolute right-0 mt-8 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                        <Menu.Item>
                            {({ active }) => (
                                <Link
                                    to={`/user/${user.id}`}
                                    className={`${
                                        active ? "bg-indigo-500 text-white" : "text-gray-700"
                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                                    {active ? (
                                        <UserIconSolid className="mr-2 h-5 w-5" aria-hidden="true" />
                                    ) : (
                                        <UserIconOutline className="mr-2 h-5 w-5" aria-hidden="true" />
                                    )}
                                    {t("actions-dropdown.button-user-settings")}
                                </Link>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={handleLogout}
                                    className={`${
                                        active ? "bg-indigo-500 text-white" : "text-gray-700"
                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                                    {active ? (
                                        <LogoutIconSolid className="mr-2 h-5 w-5" aria-hidden="true" />
                                    ) : (
                                        <LogoutIconOutline className="mr-2 h-5 w-5" aria-hidden="true" />
                                    )}
                                    {t("actions-dropdown.button-logout")}
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};
