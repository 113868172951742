import classnames from "classnames";
import React from "react";

interface IBadgeProps {
    size?: "normal" | "large";
    variant?: "success" | "danger" | "warning" | "primary" | "subtle" | "default" | "primary-outline";
    children: string | number;
    className?: string;
}

export const Badge = ({ variant = "default", size = "normal", className, children }: IBadgeProps) => {
    const badgeColor = (): string => {
        switch (variant) {
            case "success":
                return "bg-green-100 text-green-800";
            case "danger":
                return "bg-red-100 text-red-800";
            case "warning":
                return "bg-yellow-100 text-yellow-800";
            case "subtle":
                return "bg-gray-100 text-gray-700";
            case "primary":
                return "bg-indigo-600 text-white";
            case "primary-outline":
                return "text-indigo-600 bg-white";
            case "default":
            default:
                return "bg-gray-100 text-gray-800";
        }
    };
    const badgeSize = (): string => {
        switch (size) {
            case "large":
                return "px-2.5 py-0.5 font-medium";
            case "normal":
            default:
                return "px-2.5 py-0.5 text-sm ";
        }
    };

    return (
        <div
            className={classnames(
                "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
                badgeColor(),
                badgeSize(),
                className
            )}>
            {children}
        </div>
    );
};
