import { hooks as commonHooks } from "@app/common";
import { TimeUnits } from "@hotelchamp/common";
import React, { useEffect, useState } from "react";
import { Notification } from "./Notification";
import { INotification, TNotificationId } from "./types";

export interface INotificationCarouselProps {
    limit?: number;
    duration?: number;
}

export function NotificationCarousel({ limit = 1, duration = TimeUnits.Second * 5 }: INotificationCarouselProps) {
    const { pushNotification, removeNotification, notifications } = commonHooks.useNotificationManagerContext();
    const [visible, setVisible] = useState<INotification[]>([]);

    const handleNotificationClose = (id: TNotificationId) => {
        if (hasNotificationWithId(id, notifications)) {
            removeNotification(id);
        }
    };

    useEffect(() => {
        setVisible(notifications.slice(0, limit));
    }, [notifications, limit]);

    return (
        <div aria-live="assertive" className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50">
            <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                {visible.map((notification) => (
                    <Notification key={notification.id} notification={notification} onClose={handleNotificationClose} expire={duration} />
                ))}
            </div>
        </div>
    );
}

const hasNotificationWithId = (id: string, notifications: INotification[]) =>
    notifications.find((notification) => notification.id === id) !== undefined;
