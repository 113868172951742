import { queryClient } from "@app/services";
import { AxiosError } from "axios";
import { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { AuthContext } from "./components/AuthContext";
import { QueryKeys } from "./constants/QueryKeys";
import { getConfirmation, getUser, login, logout, requestPasswordReset, switchDomain, updateConfirmation } from "./queries";
import { IConfirmation } from "./types";

export const useRequestPasswordReset = () => useMutation(requestPasswordReset);

export const useLogout = () =>
    useMutation(logout, {
        onSuccess: () => {
            queryClient.setQueryData(QueryKeys.Authenticate, { user: null });
        },
    });

export const useLogin = () =>
    useMutation(login, {
        onSuccess: (data) => {
            queryClient.setQueryData(QueryKeys.Authenticate, data);
        },
        onError: (error: AxiosError) => {},
    });

export const useGetUser = () =>
    useQuery(QueryKeys.Authenticate, getUser, {
        onError: () => {
            queryClient.setQueryData(QueryKeys.Authenticate, { user: null });
        },
        retry: false,
        refetchInterval: false,
    });

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error("useAuth must be used within a AuthProvider");
    }

    return context;
};

export const useGetConfirmation = (token: string) => useQuery<IConfirmation, Error>([QueryKeys.Confirmation, { token }], () => getConfirmation(token));

export const useUpdateConfirmation = () =>
    useMutation(updateConfirmation, {
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
    });

export const useUpdateSwitchDomain = () =>
    useMutation(switchDomain, {
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
    });
