import { OutlineIcon } from "@app/common";
import { Popover } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import React from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import { INavigationItem } from "../types";
import { HorizontalDropdownNavigationDropdownPanel } from "./HorizontalDropdownNavigationDropdownPanel";

export interface IHorizontalDropdownNavigationItemProps {
    item: INavigationItem;
    isExpandable: boolean;
}

export const HorizontalDropdownNavigationMainItem = ({ item, isExpandable }: IHorizontalDropdownNavigationItemProps) => {
    const hasSubItems = !!item?.items?.length;
    const iconName: keyof typeof OutlineIcon.map = (item.icon as keyof typeof OutlineIcon.map) || "cog";

    const location = useLocation();
    const isActive = item.to && matchPath(item.to, location.pathname);

    return (
        <Popover key={item.title} className="flex">
            {({ open: isOpen }) => (
                <>
                    <div className="relative flex">
                        <Popover.Button
                            className={classnames(
                                isActive ? "text-indigo-600" : "text-gray-700 hover:text-gray-800",
                                "relative z-10  transition-colors ease-out duration-200 text-sm font-medium"
                            )}>
                            <Link to={item.to || "#"} className="flex items-center justify-center h-full">
                                <OutlineIcon
                                    name={iconName}
                                    className={classnames(
                                        "text-base font-medium text-gray-700 hover:text-gray-800",
                                        "mr-3 flex-shrink-0 h-5 w-5",
                                        isActive ? "text-indigo-600" : "text-gray-700 group-hover:text-gray-800 hover:text-gray-800"
                                    )}
                                    aria-hidden="true"
                                />
                                {item.title}
                                {hasSubItems &&
                                    isExpandable &&
                                    (isOpen ? (
                                        <ChevronUpIcon className="h-4 w-4 ml-1" aria-hidden="true" />
                                    ) : (
                                        <ChevronDownIcon className="h-4 w-4 ml-1" aria-hidden="true" />
                                    ))}
                                <span
                                    className={classnames(
                                        isActive ? "bg-indigo-600" : "",
                                        "absolute bottom-0 inset-x-0 h-0.5 transition-colors ease-out duration-200 sm:mt-5 sm:transform sm:translate-y-px"
                                    )}
                                    aria-hidden="true"
                                />
                            </Link>
                        </Popover.Button>
                    </div>

                    {isExpandable && !!item?.items?.length && <HorizontalDropdownNavigationDropdownPanel items={item.items} />}
                </>
            )}
        </Popover>
    );
};
