import { types as addressTypes } from "@app/address";
import { types as domainTypes } from "@app/domain";
import React from "react";
import { FooterCopyright } from "./footer/FooterCopyright";
import { FooterDomainAddress } from "./footer/FooterDomainAddress";

export interface IFooterProps {
    address: addressTypes.IAddress;
    domain?: domainTypes.IDomain;
}

export const Footer = ({ address, domain }: IFooterProps) => (
    <footer aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
            Footer
        </h2>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="border-t border-gray-100 py-10 sm:flex sm:items-center sm:justify-between">
                {domain && (
                    <React.Fragment>
                        <FooterDomainAddress address={address} domain={domain} />
                        <FooterCopyright domain={domain} />
                    </React.Fragment>
                )}
            </div>
        </div>
    </footer>
);
