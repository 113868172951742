import { axios } from "@app/services";
import { IProductSection, IProductSectionItemsResponse } from "./types";

/**
 * Product sections
 */

export const getProductSectionItems = async (page?: number, search?: string): Promise<IProductSectionItemsResponse> => {
    const response = await axios.get<IProductSectionItemsResponse>("/product-sections", { params: { page, search } });

    return response.data;
};

export const getProductSectionItem = async (id: number): Promise<IProductSection> => {
    const response = await axios.get<{ data: IProductSection }>(`/product-sections/${id}`);

    return response.data.data;
};

export const updateProductSectionItem = async (item: IProductSection): Promise<IProductSection> => {
    const response = await axios.put(`/product-sections/${item.id}`, item);

    return response.data;
};

export const createProductSectionItem = async (item: IProductSection): Promise<IProductSection> => {
    const response = await axios.post("/product-sections", item);

    return response.data;
};

export const deleteProductSectionItem = async (id: number) => {
    const response = await axios.delete(`product-sections/${id}`);

    return response.data;
};
