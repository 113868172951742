/* eslint-disable jsx-a11y/anchor-is-valid */
import { hooks as authHooks } from "@app/auth";
import { CartBarItem } from "@app/cart";
import { hooks as clientHooks } from "@app/client";
import { HorizontalDropdownNavigation, navigationTypes } from "@app/navigation";
import { HasOneOfRoles, Roles } from "@app/rbac";
import { UserActionsDropdown } from "@app/user";
import { CogIcon, MenuIcon, SearchIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppState } from "../hooks";

export interface ITopbarProps {
    navigationItems: navigationTypes.INavigationItem[] | undefined;
    onMobileNavigationToggle: () => void;
}

export function Topbar({ navigationItems, onMobileNavigationToggle }: ITopbarProps) {
    const auth = authHooks.useAuth();
    const location = useLocation();
    const isAdminPage = location.pathname.includes("admin");
    const {
        state: { projectId, clientId, domain, adminDomain },
    } = useAppState();
    const activeDomain = isAdminPage ? adminDomain : domain;
    const navigate = useNavigate();
    const { data: client } = clientHooks.useGetClientItem(clientId || 0, { enabled: !!clientId });
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
            <nav aria-label="Top" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="">
                    <div className="h-20 flex items-center justify-between">
                        <div className="flex-1 flex items-center lg:hidden">
                            <button
                                type="button"
                                className="-ml-2 bg-white p-2 rounded-md text-gray-400"
                                onClick={onMobileNavigationToggle}>
                                <span className="sr-only">Open menu</span>
                                <MenuIcon className="h-6 w-6" aria-hidden="true" />
                            </button>

                            <a href="#" className="ml-2 p-2 text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Search</span>
                                <SearchIcon className="w-6 h-6" aria-hidden="true" />
                            </a>
                        </div>

                        {/* Flyout menus */}
                        <HorizontalDropdownNavigation items={navigationItems} isExpandable={false} />

                        {/* Logo */}
                        <div className="flex items-center justify-content gap-4">
                            {/* <img className="h-10 w-auto " src="/concept/style26.svg" alt="" /> */}
                            {activeDomain?.avatar?.original_url ? (
                                <img className="h-10 w-auto object-cover rounded-md" src={activeDomain?.avatar?.original_url} alt="" />
                            ) : (
                                activeDomain?.company
                            )}
                            <span className="text-gray-400">|</span>
                            {client?.avatar?.original_url ? (
                                <img className="h-10 w-auto object-cover rounded-md" src={client?.avatar?.original_url} alt="" />
                            ) : (
                                client?.name
                            )}
                            {/* <Avatar url={activeDomain?.avatar?.original_url} fallBackInitials="" size={AvatarSizes.Small} />
                            {client?.avatar?.original_url ? (
                                <img src={client?.avatar.original_url} alt="" className="h-8 w-auto px-4" />
                            ) : client?.avatar?.original_url ? (
                                <Avatar url={client?.avatar.original_url} fallBackInitials="" size={AvatarSizes.Small} />
                            ) : (
                                client?.name
                            )} */}
                            {/* <Avatar url={client?.avatar.preview_url} fallBackInitials={client?.name || ""} size={AvatarSizes.Small} /> */}
                        </div>

                        <div className="flex-1 flex items-center justify-end">
                            <HasOneOfRoles role={[Roles.Admin, Roles.SuperAdmin]}>
                                <button
                                    type="button"
                                    title="Admin"
                                    onClick={() => navigate("/admin/domains/dashboard")}
                                    className="px-2 py-2 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500">
                                    <CogIcon className="h-6 w-6 flex-shrink-0" aria-hidden="true" />

                                    <span className="sr-only">Admin</span>
                                </button>
                            </HasOneOfRoles>

                            <CartBarItem
                                cartUriResolver={() => `/projects/${projectId}/cart`}
                                cartItemUriResolver={(item) => `/projects/${projectId}/catalog/${item.product.id}`}
                                catalogUriResolver={() => `/projects/${projectId}/catalog`}
                                checkoutUriResolver={() => `/projects/${projectId}/checkout`}
                            />

                            {auth.user.name && <UserActionsDropdown user={auth.user} />}
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}
