import { AddressLine, types as addressTypes } from "@app/address";
import { types as domainTypes } from "@app/domain";
import React from "react";

export interface IFooterDomainAddressProps {
    address: addressTypes.IAddress;
    domain: domainTypes.IDomain;
}

export const FooterDomainAddress = ({ address, domain }: IFooterDomainAddressProps) => (
    <div className="flex items-center justify-center text-sm text-gray-500">
        <img src={ "/concept/style26.svg"} alt="" className="w-8" />
        <p className="ml-3 border-l border-gray-200 pl-3">
            <AddressLine address={address} />
        </p>
    </div>
);
