import { types as clientTypes } from "@app/client";
import { requestUtils } from "@app/common";
import { types as orderTypes } from "@app/order";
import { types as paymentTypes } from "@app/paymtent";
import { types as productTypes } from "@app/product";
import { types as productSectionTypes } from "@app/productSection";
import { types as projectTypes } from "@app/project";
import { axios } from "@app/services";
import { types as userTypes } from "@app/user";
import { IDomain, IDomainItemsResponse, IDomainPublic } from "./types";

/**
 * domains
 */

export const getDomainItems = async (page: number, search?: string): Promise<IDomainItemsResponse> => {
    const response = await axios.get<IDomainItemsResponse>("/domains", { params: { page, search } });

    return response.data;
};

export const getDomainItem = async (id: number): Promise<IDomain> => {
    const response = await axios.get<{ data: IDomain }>(`/domains/${id}`);

    return response.data.data;
};

export const getPublicDomainItem = async (hostname: string): Promise<IDomainPublic> => {
    const response = await axios.get<{ data: IDomainPublic }>(`/domains-public/${hostname}`);

    return response.data.data;
};

export const updateDomainItem = async (item: IDomain): Promise<IDomain> => {
    const formData = requestUtils.constructFormData(item, ["avatar", "welcome_image"], "PUT");

    const response = await axios.post(`/domains/${item.id}`, formData, {
        headers: {
            "content-type": "multipart/form-data",
        },
    });

    return response.data;
};

export const createDomainItem = async (item: IDomain): Promise<IDomain> => {
    const formData = requestUtils.constructFormData(item, ["avatar", "welcome_image"]);

    const response = await axios.post("/domains", formData, {
        headers: {
            "content-type": "multipart/form-data",
        },
    });

    return response.data;
};

export const deleteDomainItem = async (domainId: number) => {
    const response = await axios.delete(`domains/${domainId}`);

    return response.data;
};

/**
 * domains/products
 */

export const getDomainProductItems = async (domainId: number, page: number, search?: string): Promise<productTypes.IProductResponse> => {
    const response = await axios.get<productTypes.IProductResponse>(`/domains/${domainId}/products`, { params: { page, search } });

    return response.data;
};

export const createDomainProductItem = async (domainId: number, item: productTypes.IProduct): Promise<productTypes.IProduct> => {
    const formData = requestUtils.constructFormData(item, ["media"]);

    const response = await axios.post(`/domains/${domainId}/products`, formData, {
        headers: {
            "content-type": "multipart/form-data",
        },
    });

    return response.data;
};

/**
 * domains/projects
 */

export const createDomainProjectItem = async (project: projectTypes.IProject): Promise<projectTypes.IProject> => {
    const response = await axios.post<{ data: projectTypes.IProject }>(`/domains/${project.domain_id}/projects`, project);

    return response.data.data;
};

export const getDomainProjectItems = async (domainId: number): Promise<projectTypes.IProject[]> => {
    const response = await axios.get<{ data: projectTypes.IProject[] }>(`domains/${domainId}/projects`);

    return response.data.data;
};

/**
 * domains/users
 */

export const getDomainUserItems = async (domainId: number, page: number, search?: string): Promise<userTypes.IUserItemsResponse> => {
    const response = await axios.get<userTypes.IUserItemsResponse>(`domains/${domainId}/users`, { params: { page, search } });

    return response.data;
};

export const createDomainUserItem = async (domainId: number, user: userTypes.IUser): Promise<userTypes.IUser> => {
    const response = await axios.post<userTypes.IUser>(`domains/${domainId}/users`, { ...user });

    return response.data;
};

/**
 * domain/orders
 */

export const getDomainOrders = async (domainId: number, page: number, search?: string): Promise<orderTypes.IOrderResponse> => {
    const response = await axios.get<orderTypes.IOrderResponse>(`domains/${domainId}/orders`, {
        params: { page, search },
    });

    return response.data;
};

/**
 * domain/clients
 */

export const getDomainClientItems = async (domainId: number, page: number, search?: string): Promise<clientTypes.IClientItemsResponse> => {
    const response = await axios.get<clientTypes.IClientItemsResponse>(`domains/${domainId}/clients`, { params: { page, search } });

    return response.data;
};

export const createDomainClientItem = async (domainId: number, item: clientTypes.IClient): Promise<clientTypes.IClient> => {
    const formData = requestUtils.constructFormData(item, ["avatar"]);

    const response = await axios.post(`/domains/${domainId}/clients`, formData, {
        headers: {
            "content-type": "multipart/form-data",
        },
    });

    return response.data;
};

export const getDomainPaymentItem = async (domainId: number): Promise<paymentTypes.IPayment> => {
    const response = await axios.get<paymentTypes.IPayment>(`domains/${domainId}/payments`);

    return response.data;
};

/**
 * domain/productSections
 */

export const getDomainProductSectionItems = async (
    domainId: number,
    page?: number,
    search?: string
): Promise<productSectionTypes.IProductSectionItemsResponse> => {
    const response = await axios.get<productSectionTypes.IProductSectionItemsResponse>(`domains/${domainId}/product-sections`, {
        params: { page, search },
    });

    return response.data;
};

export const createDomainProductSectionItem = async (
    domainId: number,
    item: productSectionTypes.IProductSection
): Promise<productSectionTypes.IProductSection> => {
    const response = await axios.post(`/domains/${domainId}/product-sections`, item);

    return response.data;
};
