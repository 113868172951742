import { Popover, Transition } from "@headlessui/react";
import React from "react";
import { Link } from "react-router-dom";
import { INavigationItem } from "../types";

export interface IHorizontalDropdownNavigationDropdownPanelProps {
    items: INavigationItem[];
}

export const HorizontalDropdownNavigationDropdownPanel = ({ items }: IHorizontalDropdownNavigationDropdownPanelProps) => (
    <Transition
        as={React.Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <Popover.Panel className="absolute z-20 top-full inset-x-0">
            {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
            <div className="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />

            <div className="relative bg-white">
                <div className="max-w-7xl mx-auto px-8">
                    <div className="grid grid-cols-2 gap-y-10 gap-x-8 py-16">
                        <div className="grid grid-cols-3 gap-y-10 gap-x-8 text-sm text-gray-500">
                            {items.map((item, itemIndex) => (
                                <div key={itemIndex} className="space-y-10">
                                    <div key={item.title}>
                                        <p id={`${item.id}-heading`} className="font-medium text-gray-900">
                                            {item.title}
                                        </p>
                                        <ul role="list" aria-labelledby={`${item.id}-heading`} className="mt-4 space-y-4">
                                            {item.items?.map((subItem) => (
                                                <li key={subItem.title} className="flex">
                                                    <Link to={subItem.to || ""} className="hover:text-gray-800">
                                                        {subItem.title}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Popover.Panel>
    </Transition>
);
