const hostname = window.location.hostname;

export const config = {
    apiUrl: `https://${hostname}/api/v1`,
    appUrl: `//${hostname}`,
    // apiUrl: process.env.REACT_APP_PLATFORM_API_URL,
    // appUrl: `//${process.env.REACT_APP_DOMAIN}`,
};

// console.log("config", {
//     "process.env.REACT_APP_PLATFORM_API_URL": process.env.REACT_APP_PLATFORM_API_URL,
//     "process.env.REACT_APP_DOMAIN": process.env.REACT_APP_DOMAIN,
//     "window.location.hostname": window.location.hostname,
//     "apiUrl": config.apiUrl,
//     "appUrl": config.appUrl,
// });

/*
apiUrl: "https://furniture-portal.test/api/v1"
appUrl: "//furniture-portal.test"
*/
