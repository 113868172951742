/* eslint-disable quote-props */
import { i18next } from "@app/services";
import { INavigationItem } from "./types";

export async function getMainNavigationItems(): Promise<INavigationItem[]> {
    return initialMainNavigationItems;
}

export async function getAdminNavigationItems(): Promise<INavigationItem[]> {
    return initialAdminNavigationItems;
}

export async function getSuperAdminNavigationItems(): Promise<INavigationItem[]> {
    return initialSuperAdminNavigationItems;
}

// export function useGetMainNavigationItems() {
//     const queryClient = useQueryClient();

//     return useQuery(QueryKeys.MainNavigationItems, getMainNavigationItems, {
//         onError: () => {
//             // Set a backup navigation if the backend navigation fails
//             queryClient.setQueryData(QueryKeys.MainNavigationItems, initialMainNavigationItems);
//         },
//         retry: 0, // TODO: fine tune this value, works for now
//         select: selectMainNavigationItems,
//         staleTime: Infinity, // TODO: fine tune this value, works for now
//     });
// }

/**
 * Selectors
 */

// const selectMainNavigationItems = (items: INavigationItem[]) =>
//     items.map((item) => ({
//         ...item,
//         icon: mainNavigationIconMap[item.icon as keyof typeof mainNavigationIconMap] || mainNavigationIconMap.home, // map server side icon names to client side icons
//     }));

/**
 * Private/protected
 */

const decorateItems = (items: INavigationItem[]) => items.map((item) => ({ ...item, title: i18next.t(item.title) }));

const initialMainNavigationItems: INavigationItem[] = decorateItems([
    {
        id: 2,
        title: "navigation:item.projects-catalog",
        // icon: "book-open",
        icon: "home",
        to: "/projects/{projectId}/catalog",
    },
    {
        id: 4,
        title: "navigation:item.projects-orders",
        icon: "shopping-cart",
        to: "/projects/{projectId}/orders",
        items: [],
    },
]);

const initialAdminNavigationItems: INavigationItem[] = decorateItems([
    // {
    //     id: 11,
    //     title: "navigation:admin.item.domains-dashboard",
    //     icon: "home",
    //     to: "/admin/domains/dashboard",
    // },
    {
        id: 4,
        title: "navigation:admin.item.domains-orders",
        icon: "shopping-cart",
        to: "/admin/domains/orders",
        items: [],
    },
    {
        id: 11,
        title: "navigation:admin.item.domains-clients",
        icon: "archive",
        to: "/admin/domains/clients",
    },
    {
        id: 11,
        title: "navigation:admin.item.domains-products",
        icon: "archive",
        to: "/admin/domains/products",
    },
    // {
    //     id: 12,
    //     title: "navigation:admin.item.clients-projects",
    //     icon: "clipboard-list",
    //     to: "/admin/domains/projects",
    // },
    {
        id: 13,
        title: "navigation:admin.item.domains-users",
        icon: "users",
        to: "/admin/domains/users",
    },
    {
        id: 15,
        title: "navigation:admin.item.domains-product-sections",
        icon: "archive",
        to: "/admin/domains/product-sections",
    },
    {
        id: 2,
        title: "navigation:admin.item.domains-item",
        icon: "globe-alt",
        to: "/admin/domains/{domainId}",
    },
]);

const initialSuperAdminNavigationItems: INavigationItem[] = decorateItems([
    {
        id: 1311,
        title: "navigation:super-admin.item.users",
        icon: "users",
        to: "/admin/super/users",
    },
    {
        id: 1411,
        title: "navigation:super-admin.item.taxes",
        icon: "receipt-tax",
        to: "/admin/super/taxes",
    },
    {
        id: 1511,
        title: "navigation:super-admin.item.domains",
        icon: "globe-alt",
        to: "/admin/super/domains",
    },
    {
        id: 1512,
        title: "navigation:super-admin.item.mail-templates",
        icon: "globe-alt",
        to: "/admin/super/mail-templates",
    },
]);

// const initialMainNavigationItems: INavigationItem[] = [
// {
//     handle: null,
//     title: "Home",
//     to: "/home",
//     icon: "/images/icons/navigation/menu-icon-home.svg",
//     heroIcon: null,
//     hint: null,
//     default: true,
//     children: [],
// },
// {
//     handle: "admin",
//     title: "Admin",
//     to: "/admin/settings",
//     icon: "/images/icons/navigation/menu-icon-admin.svg",
//     heroIcon: null,
//     hint: null,
//     default: null,
//     children: [
//         {
//             handle: "settings",
//             title: "Settings",
//             to: "/admin/settings",
//             icon: null,
//             heroIcon: null,
//             hint: null,
//             default: null,
//             children: [],
//         },
//     ],
// },
// {
//     handle: null,
//     title: "Help",
//     to: "",
//     icon: "/images/icons/navigation/menu-icon-help.svg",
//     heroIcon: null,
//     hint: null,
//     default: true,
//     children: [],
// },
// ];
