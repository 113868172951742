import { utilsQuery } from "@app/common";

const define = utilsQuery.defineQueryKeyCreator("domains");

export const QueryKeys = {
    DomainItems: define("domain-items"),
    DomainItem: define("domain-item"),
    DomainPublicItem: define("domain-public-item"),
    DomainProductItems: define("domain-product-items"),
    DomainProductItem: define("domain-product-item"),
    DomainProjectItems: define("domain-project-items"),
    DomainUserItems: define("domain-user-items"),
    DomainClientItems: define("domain-client-items"),
    DomainOrderItems: define("domain-order-items"),
    DomainPaymentItem: define("domain-payment-item"),
    DomainProductSectionItems: define("domain-product-section-items"),
} as const;
