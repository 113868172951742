import { types as commonTypes } from "@app/common";
import { types as orderTypes } from "@app/order";
import { types as productTypes } from "@app/product";
import { queryClient } from "@app/services";
import { queries as userQueries, types as userTypes } from "@app/user";
import { useMutation, useQuery } from "react-query";
import { QueryKeys } from "./constants/QueryKeys";
import {
    createProjectItem,
    createProjectOrderItem,
    createProjectUserItem,
    getProjectItem,
    getProjectOrders,
    getProjectProductItem,
    getProjectProductItems,
    getProjectUserItems,
    inviteUsersToProject,
    updateProjectItem,
    updateProjectProductItem,
    type IGetProjectProductItemsAdditionalParams,
} from "./queries";
import { IProject, IProjectProduct } from "./types";

/**
 * projects
 */

export const useGetProjectItem = (id: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IProject, Error>([QueryKeys.ProjectItem, id], () => getProjectItem(id), options);

export const useUpdateProjectItem = () =>
    useMutation(updateProjectItem, {
        onSuccess: (updatedItem, postedItem) => {
            // queryClient.invalidateQueries([QueryKeys.ProjectItem, postedItem.id]);

            queryClient.invalidateQueries({
                predicate: (query) => query.queryHash.includes("project") || query.queryHash.includes("product"), // just clear all project-item relates to also take into account related state in other modules
            });
        },
    });

export const useCreateProjectItem = () => useMutation(createProjectItem);

/**
 * projects/products
 */

export const useGetProjectProductItem = (projectId: number, productId: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IProjectProduct, Error>(
        [QueryKeys.ProjectProductItem, { projectId, productId }],
        () => getProjectProductItem(projectId, productId),
        options
    );

export const useGetProjectProductItems = (
    projectId: number,
    page: number,
    search?: string,
    additionalParams?: IGetProjectProductItemsAdditionalParams,
    options?: commonTypes.IUseGetOptions
) =>
    useQuery<productTypes.IProductResponse, Error>(
        [QueryKeys.ProjectProductItems, { projectId, page, search }],
        () => getProjectProductItems(projectId, page, search, additionalParams),
        options
    );

export const useUpdateProjectProductItem = (projectId: number) =>
    useMutation((item: productTypes.IProduct) => updateProjectProductItem(projectId, item));

/**
 * projects/users
 */

export const useCreateProjectUserItem = (projectId: number) =>
    useMutation((item: userTypes.IUser) => createProjectUserItem(projectId, item));

export const useGetProjectUserItems = (projectId: number, page: number, search?: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<userTypes.IUserItemsResponse, Error>(
        [QueryKeys.ProjectUserItems, { id: projectId, page, search }],
        () => getProjectUserItems(projectId, page, search),
        options
    );

export const useDeleteProjectUserItem = () =>
    useMutation(userQueries.deleteUserItem, {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.ProjectUserItems]);
        },
    });

export const useInviteUsersToProject = () => useMutation(inviteUsersToProject);

/**
 * projects/orders
 */

export const useGetProjectOrders = (projectId: number, page: number, search: string | null) =>
    useQuery<orderTypes.IOrderResponse, Error>([QueryKeys.ProjectOrderItems, { projectId, page, search }], () =>
        getProjectOrders(projectId, page, search)
    );

export const useCreateProjectOrderItem = (projectId: number) =>
    useMutation((item: orderTypes.IOrder) => createProjectOrderItem(projectId, item));
