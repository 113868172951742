import { requestUtils } from "@app/common";
import { axios } from "@app/services";
import { IProduct, TProductId, TProductStockList } from "./types";

/**
 * products
 */

export const getProductItem = async (id: number): Promise<IProduct> => {
    const response = await axios.get<{ data: IProduct }>(`/products/${id}`);

    return response.data.data;
};

export const updateProductItem = async (product: IProduct): Promise<IProduct> => {
    const formData = requestUtils.constructFormData(product, ["media"], "PUT");

    // React.ChangeEvent<HTMLInputElement>

    const response = await axios.post(`products/${product.id}`, formData, {
        headers: {
            "content-type": "multipart/form-data",
        },
        onUploadProgress: (progress: any) => {
            console.log("progress: ", progress);
        },
    });

    return response.data;
};

export const deleteProductItem = async (productId: number) => {
    const response = await axios.delete(`products/${productId}`);

    return response.data;
};

export const getProductStockList = async (productIds: TProductId[]): Promise<TProductStockList> => {
    const response = await axios.post<TProductStockList>("products/getStockList", {
        productIds,
    });

    return response.data;
};
