import { LoadingMask } from "@ui";
import React from "react";
import { useGetUser, useLogin, useLogout } from "../hooks";
import { AuthContext } from "./AuthContext";

export interface IAuthLoaderProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

/**
 * AuthLoaderProvider
 * Decorates AuthContext.Provider with loader functionality
 * @param props
 * @returns
 */
export function AuthLoaderProvider({ children }: IAuthLoaderProviderProps) {
    const { data: user, error, isLoading, isIdle, isError } = useGetUser();

    const login = React.useCallback(useLogin, []);
    const logout = React.useCallback(useLogout, []);

    const value = React.useMemo(() => (user ? { ...user, login, logout } : undefined), [user, login, logout]);

    if (isLoading || isIdle) {
        return <LoadingMask isLoading={true} />;
    }

    if (isError) {
        return <div>Something wrong... {JSON.stringify(error)}</div>;
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
