import classnames from "classnames";
import React from "react";

interface IExportContentProps {
    children: any;
    cardFluid?: boolean;
    className?: string;
    style?: React.CSSProperties;
}

export const CardContent = React.forwardRef(
    ({ children, cardFluid = false, className, style }: IExportContentProps, ref: React.ForwardedRef<HTMLDivElement>) => (
        <div style={style} className={classnames({ "px-4 py-5 sm:p-6": !cardFluid }, className)} ref={ref}>
            {children}
        </div>
    )
);
