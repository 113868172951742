import { types as productTypes } from "@app/product";
import { queryClient } from "@app/services";
import { useMutation, useQuery } from "react-query";
import { QueryKeys } from "./constants/QueryKeys";
import {
    addProduct,
    deleteCartItems,
    deleteProduct,
    getCartItems,
    getHasAddedToCartDialogBeenShown,
    setCartItems,
    setHasAddedToCartDialogBeenShown,
    syncCartItemsStock,
} from "./queries";
import { ICartItem } from "./types";

export const useFetchCartItems = (context?: string | number) =>
    useQuery<ICartItem[], Error>([QueryKeys.CartItems, context], () => getCartItems(context));

export const useSetCartItems = (context?: string | number) =>
    useMutation((items: ICartItem[]) => setCartItems(items, context), {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.CartItems, context]);
        },
    });

export const useAddProduct = (context?: string | number) =>
    useMutation((item: ICartItem) => addProduct(item, context), {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.CartItems, context]);
        },
    });

export const useDeleteProduct = (context?: string | number) =>
    useMutation((product: productTypes.IProduct) => deleteProduct(product, 1, context), {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.CartItems, context]);
        },
    });

export const useDeleteCartItems = (context?: string | number) =>
    useMutation(() => deleteCartItems(context), {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.CartItems, context]);
        },
    });

export const useSyncCartItemsStock = (context?: string | number) =>
    useMutation(() => syncCartItemsStock(context), {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.CartItems, context]);
        },
    });

export const useSetHasAddedToCartDialogBeenShown = (context?: string | number) =>
    useMutation((hasBeenShown: boolean) => setHasAddedToCartDialogBeenShown(hasBeenShown, context), {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.HasAddedToCartDialogBeenShown, context]);
        },
    });

export const useGetHasAddedToCartDialogBeenShown = (context?: string | number) =>
    useQuery<boolean, Error>(QueryKeys.HasAddedToCartDialogBeenShown, () => getHasAddedToCartDialogBeenShown(context));
