import { hooks as authHooks, pages as authPages } from "@app/auth";
import { ClientTabContentWithNavigationSidebarLayout } from "@app/client";
import { ScrollToTop } from "@app/common";
import { ProjectTabContentWithNavigationSidebarLayout } from "@app/project";
import { HasOneOfRoles, Roles } from "@app/rbac";
import { commonHooks, LoadingMask } from "@ui";
import { get } from "lodash";
import React, { Fragment, lazy, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, Route, Routes as ReactRouterRoutes, useNavigate } from "react-router-dom";
import { useAppState } from "../hooks";
import { ContentWithNavigationSidebarLayout } from "./layouts/ContentWithNavigationSidebarLayout";
import { ContentWithNavigationTopbarLayout } from "./layouts/ContentWithNavigationTopbarLayout";
import { withAppStateResolver } from "./withAppStateResolver";

const MailTemplateOverviewPage = lazy(() =>
    import("@app/mailTemplate/pages/MailTemplateOverviewPage").then((module) => ({ default: module.MailTemplateOverviewPage }))
);

const MailTemplateItemPage = lazy(() =>
    import("@app/mailTemplate/pages/MailTemplateItemPage").then((module) => ({ default: module.MailTemplateItemPage }))
);

const DomainUserOverviewPage = lazy(() =>
    import("@app/domain/pages/DomainUserOverviewPage").then((module) => ({ default: module.DomainUserOverviewPage }))
);
const DomainUserItemPage = lazy(() =>
    import("@app/domain/pages/DomainUserItemPage").then((module) => ({ default: module.DomainUserItemPage }))
);

const DomainClientOverviewPage = lazy(() =>
    import("@app/domain/pages/DomainClientOverviewPage").then((module) => ({ default: module.DomainClientOverviewPage }))
);

const DomainProductOverviewPage = lazy(() =>
    import("@app/domain/pages/DomainProductOverviewPage").then((module) => ({ default: module.DomainProductOverviewPage }))
);

const DomainOrderOverviewPage = lazy(() =>
    import("@app/domain/pages/DomainOrderOverviewPage").then((module) => ({ default: module.DomainOrderOverviewPage }))
);

const OrderItemPage = lazy(() => import("@app/order/pages/OrderItemPage").then((module) => ({ default: module.OrderItemPage })));

const ProjectCatalogPage = lazy(() =>
    import("@app/project/pages/ProjectCatalogPage").then((module) => ({ default: module.ProjectCatalogPage }))
);
const ProjectCatalogItemPage = lazy(() =>
    import("@app/project/pages/ProjectCatalogItemPage").then((module) => ({ default: module.ProjectCatalogItemPage }))
);

const CatalogPage = lazy(() => import("@app/catalog/pages/CatalogPage").then((module) => ({ default: module.CatalogPage })));
const CatalogItemPage = lazy(() => import("@app/catalog/pages/CatalogItemPage").then((module) => ({ default: module.CatalogItemPage })));

const ProjectCartOverviewPage = lazy(() =>
    import("@app/project/pages/ProjectCartOverviewPage").then((module) => ({ default: module.ProjectCartOverviewPage }))
);

const CartOverviewPage = lazy(() => import("@app/cart/pages/CartOverviewPage").then((module) => ({ default: module.CartOverviewPage })));

const CheckoutPage = lazy(() => import("@app/checkout/pages/CheckoutPage").then((module) => ({ default: module.CheckoutPage })));

const ProjectCheckoutDonePage = lazy(() =>
    import("@app/project/pages/ProjectCheckoutDonePage").then((module) => ({ default: module.ProjectCheckoutDonePage }))
);

const ProjectCheckoutPage = lazy(() =>
    import("@app/project/pages/ProjectCheckoutPage").then((module) => ({ default: module.ProjectCheckoutPage }))
);

const DomainProductItemPage = lazy(() =>
    import("@app/domain/pages/DomainProductItemPage").then((module) => ({ default: module.DomainProductItemPage }))
);

const DomainProductSectionItemPage = lazy(() =>
    import("@app/domain/pages/DomainProductSectionItemPage").then((module) => ({ default: module.DomainProductSectionItemPage }))
);

const DomainClientItemPage = lazy(() =>
    import("@app/domain/pages/DomainClientItemPage").then((module) => ({ default: module.DomainClientItemPage }))
);

const TaxItemPage = lazy(() => import("@app/taxes/pages/TaxItemPage").then((module) => ({ default: module.TaxItemPage })));
const TaxOverviewPage = lazy(() => import("@app/taxes/pages/TaxOverviewPage").then((module) => ({ default: module.TaxOverviewPage })));

const DomainItemPage = lazy(() => import("@app/domain/pages/DomainItemPage").then((module) => ({ default: module.DomainItemPage })));
const DomainOverviewPage = lazy(() =>
    import("@app/domain/pages/DomainOverviewPage").then((module) => ({ default: module.DomainOverviewPage }))
);
const DomainDashboardPage = lazy(() =>
    import("@app/domain/pages/DomainDashboardPage").then((module) => ({ default: module.DomainDashboardPage }))
);

const ClientDashboardPage = lazy(() =>
    import("@app/client/pages/ClientDashboardPage").then((module) => ({ default: module.ClientDashboardPage }))
);

const UserOverviewPage = lazy(() => import("@app/user/pages/UserOverviewPage").then((module) => ({ default: module.UserOverviewPage })));
const UserItemPage = lazy(() => import("@app/user/pages/UserItemPage").then((module) => ({ default: module.UserItemPage })));

const ProjectDashboardPage = lazy(() =>
    import("@app/project/pages/ProjectDashboardPage").then((module) => ({ default: module.ProjectDashboardPage }))
);
const ProjectUserOverviewPage = lazy(() =>
    import("@app/project/pages/ProjectUserOverviewPage").then((module) => ({ default: module.ProjectUserOverviewPage }))
);
const ProjectUserItemPage = lazy(() =>
    import("@app/project/pages/ProjectUserItemPage").then((module) => ({ default: module.ProjectUserItemPage }))
);
const ProjectProductItemPage = lazy(() =>
    import("@app/project/pages/ProjectProductItemPage").then((module) => ({ default: module.ProjectProductItemPage }))
);
const ProjectProductOverviewPage = lazy(() =>
    import("@app/project/pages/ProjectProductOverviewPage").then((module) => ({ default: module.ProjectProductOverviewPage }))
);
const AdminProjectOrderOverviewPage = lazy(() =>
    import("@app/project/pages/AdminProjectOrderOverviewPage").then((module) => ({ default: module.AdminProjectOrderOverviewPage }))
);
const ProjectOrderOverviewPage = lazy(() =>
    import("@app/project/pages/ProjectOrderOverviewPage").then((module) => ({ default: module.ProjectOrderOverviewPage }))
);
const ProjectItemPage = lazy(() => import("@app/project/pages/ProjectItemPage").then((module) => ({ default: module.ProjectItemPage })));

const ProjectOrderItemPage = lazy(() =>
    import("@app/project/pages/ProjectOrderItemPage").then((module) => ({ default: module.ProjectOrderItemPage }))
);

const DomainProductSectionOverviewPage = lazy(() =>
    import("@app/domain/pages/DomainProductSectionOverviewPage").then((module) => ({ default: module.DomainProductSectionOverviewPage }))
);

const ClientBranchItemPage = lazy(() =>
    import("@app/client/pages/ClientBranchItemPage").then((module) => ({ default: module.ClientBranchItemPage }))
);

const ClientBranchOverviewPage = lazy(() =>
    import("@app/client/pages/ClientBranchOverviewPage").then((module) => ({ default: module.ClientBranchOverviewPage }))
);

const ClientProjectItemPage = lazy(() =>
    import("@app/client/pages/ClientProjectItemPage").then((module) => ({ default: module.ClientProjectItemPage }))
);

const ClientProjectOverviewPage = lazy(() =>
    import("@app/client/pages/ClientProjectOverviewPage").then((module) => ({ default: module.ClientProjectOverviewPage }))
);

const CustomerOrderOverviewPage = lazy(() =>
    import("@app/customer/pages/CustomerOrderOverviewPage").then((module) => ({ default: module.CustomerOrderOverviewPage }))
);
const UserProfilePage = lazy(() => import("@app/user/pages/UserProfilePage").then((module) => ({ default: module.UserProfilePage })));

/**
 * AppStateResolver
 * Helper component to wrap all routes to have top level access to useParams for appState
 */
const AppStateResolver = withAppStateResolver(() => (
    <React.Fragment>
        <Outlet />
    </React.Fragment>
));

const WithRoleAdminAccessCheck = () => {
    const { t } = useTranslation("common");

    return (
        <HasOneOfRoles
            role={[Roles.Admin, Roles.SuperAdmin]}
            fallback={
                <div className="h-screen w-screen flex justify-center items-center">
                    <div>
                        <h1 className="text-gray-500 text-3xl">{t("text.not-allowed")}</h1>
                    </div>
                </div>
            }>
            <Outlet />
        </HasOneOfRoles>
    );
};

const WithRoleUserAccessCheck = () => {
    const { t } = useTranslation("common");

    return (
        <HasOneOfRoles
            role={[Roles.Admin, Roles.SuperAdmin, Roles.User]}
            fallback={
                <div className="h-screen w-screen flex justify-center items-center">
                    <div>
                        <h1 className="text-gray-500 text-3xl">{t("text.not-allowed")}</h1>
                    </div>
                </div>
            }>
            <Outlet />
        </HasOneOfRoles>
    );
};

const WithRequiredAuthDataCheck = () => {
    const { t } = useTranslation("common");
    const auth = authHooks.useAuth();
    const requiredKeys = ["activeDomain", "defaultClientId", "defaultProjectId", "domains", "user", "user.roles"];
    const invalidDataKeys = requiredKeys.filter((requiredKey) => {
        const value = get(auth, requiredKey);

        return !value || (Array.isArray(value) && !value.length);
    });
    const isAuthDataValid = invalidDataKeys.length === 0;

    useEffect(() => {
        if (!isAuthDataValid) {
            console.error("Setup is incomplete. Missing: ", invalidDataKeys, " in auth data: ", auth);
        }
    }, [isAuthDataValid]);

    return (
        <React.Fragment>
            {/* {isAuthDataValid ? ( */}
            {true ? (
                <Outlet />
            ) : (
                <div className="h-screen w-screen flex justify-center items-center">
                    <div>
                        <h1 className="text-gray-500 text-3xl">{t("text.incomplete-setup", { invalidKeys: invalidDataKeys })}</h1>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

const NavigateToCatalog = () => {
    const {
        state: { projectId },
    } = useAppState();
    const navigate = useNavigate();

    commonHooks.useTimeout(() => {
        if (projectId) {
            navigate(`/projects/${projectId}/catalog`);
        } else {
            navigate("/");
        }
    }, 125);

    // useEffect(() => {
    //     if (projectId) {
    //         navigate(`/projects/${projectId}/catalog`);
    //     }
    // }, [projectId]);

    return <React.Fragment></React.Fragment>;
};

export function Routes() {
    const auth = authHooks.useAuth();

    return (
        <React.Fragment>
            <ScrollToTop />
            <React.Suspense fallback={<LoadingMask isLoading={true} />}>
                <ReactRouterRoutes>
                    <Route element={<AppStateResolver />}>
                        {!auth.user ? (
                            <React.Fragment>
                                <Route path="/auth">
                                    <Route path="login" element={<authPages.LoginPage />} />
                                    <Route path="confirmation/:token" element={<authPages.ConfirmationPage />} />
                                    <Route path="forgot-password" element={<authPages.ForgotPasswordPage />} />
                                </Route>
                                <Route path="*" element={<Navigate replace to="/auth/login" />} />
                            </React.Fragment>
                        ) : (
                            <Fragment>
                                <Route element={<WithRequiredAuthDataCheck />}>
                                    <Route element={<WithRoleUserAccessCheck />}>
                                        <Route path="/auth/login" element={<NavigateToCatalog />} />
                                        <Route path="/" element={<ContentWithNavigationTopbarLayout />}>
                                            <Route path="/home" element={<CatalogPage />} />
                                            <Route path="/catalog" element={<CatalogPage />} />
                                            <Route path="/catalog/:id" element={<CatalogItemPage />} />
                                            <Route path="/cart" element={<CartOverviewPage />} />
                                            <Route path="/checkout" element={<CheckoutPage />} />
                                            <Route path="/customer/orders" element={<CustomerOrderOverviewPage />} />
                                            <Route path="/user/:userId" element={<UserProfilePage />} />
                                            <Route path="/projects/:projectId">
                                                <Route path="catalog" element={<ProjectCatalogPage />} />
                                                <Route path="catalog/:id" element={<ProjectCatalogItemPage />} />
                                                <Route path="cart" element={<ProjectCartOverviewPage />} />
                                                {/* <Route path="checkout" element={<ProjectCheckoutPage />} /> */}
                                                <Route path="checkout-finished" element={<ProjectCheckoutDonePage />} />
                                                <Route path="orders" element={<ProjectOrderOverviewPage />} />
                                                <Route path="orders/:orderId" element={<ProjectOrderItemPage />} />
                                            </Route>
                                        </Route>
                                        <Route path="/projects/:projectId/checkout" element={<ProjectCheckoutPage />} />
                                    </Route>
                                    <Route element={<WithRoleAdminAccessCheck />}>
                                        <Route
                                            path="/admin/projects/:projectId/"
                                            element={<ProjectTabContentWithNavigationSidebarLayout />}>
                                            <Route path="dashboard" element={<ProjectDashboardPage />} />
                                            <Route path="users" element={<ProjectUserOverviewPage />} />
                                            <Route path="users/:userId" element={<ProjectUserItemPage />} />
                                            <Route path="settings" element={<ProjectItemPage />} />
                                            <Route path="products" element={<ProjectProductOverviewPage />} />
                                            <Route path="products/:productId" element={<ProjectProductItemPage />} />
                                            <Route path="orders" element={<AdminProjectOrderOverviewPage />} />
                                        </Route>
                                        <Route path="/admin/clients/:clientId/" element={<ClientTabContentWithNavigationSidebarLayout />}>
                                            <Route path="dashboard" element={<ClientDashboardPage />} />
                                            <Route path="settings" element={<DomainClientItemPage />} />
                                            <Route path="branches" element={<ClientBranchOverviewPage />} />
                                            <Route path="branches/:branchId" element={<ClientBranchItemPage />} />
                                            <Route path="branches/new" element={<ClientBranchItemPage />} />
                                            <Route path="projects" element={<ClientProjectOverviewPage />} />
                                            <Route path="projects/:projectId" element={<ClientProjectItemPage />} />
                                            <Route path="projects/new" element={<ClientProjectItemPage />} />
                                            {/* <Route path="users" element={<ProjectUserOverviewPage />} />
                        <Route path="users/:id" element={<ProjectUserItemPage />} />
                        <Route path="settings" element={<ProjectItemPage />} />
                        <Route path="products" element={<ProjectProductOverviewPage />} />
                        <Route path="orders" element={<AdminProjectOrderOverviewPage />} /> */}
                                        </Route>

                                        <Route path="/admin" element={<ContentWithNavigationSidebarLayout />}>
                                            <Route path="" element={<DomainOrderOverviewPage />} />
                                            <Route path="domains/:id" element={<DomainItemPage />} />
                                            <Route path="domains/new" element={<DomainItemPage />} />
                                            <Route path="domains">
                                                <Route path="dashboard" element={<DomainDashboardPage />} />
                                                <Route path="products" element={<DomainProductOverviewPage />} />
                                                <Route path="products/:id" element={<DomainProductItemPage />} />
                                                <Route path="products/new" element={<DomainProductItemPage />} />
                                                <Route path="product-sections" element={<DomainProductSectionOverviewPage />} />
                                                <Route path="product-sections/:id" element={<DomainProductSectionItemPage />} />
                                                <Route path="product-sections/new" element={<DomainProductSectionItemPage />} />
                                                <Route path="clients" element={<DomainClientOverviewPage />} />
                                                <Route path="clients/:id" element={<DomainClientItemPage />} />
                                                <Route path="clients/new" element={<DomainClientItemPage />} />
                                                <Route path="users" element={<DomainUserOverviewPage />} />
                                                <Route path="users/:id" element={<DomainUserItemPage />} />
                                                <Route path="users/new" element={<DomainUserItemPage />} />
                                                <Route path="orders" element={<DomainOrderOverviewPage />} />
                                                <Route path="orders/:id" element={<OrderItemPage />} />
                                            </Route>

                                            <Route path="user/:userId" element={<UserProfilePage />} />
                                        </Route>

                                        <Route path="/admin/super" element={<ContentWithNavigationSidebarLayout />}>
                                            <Route path="domains" element={<DomainOverviewPage />} />
                                            <Route path="domains/:id" element={<DomainItemPage />} />
                                            <Route path="domains/new" element={<DomainItemPage />} />
                                            <Route path="taxes" element={<TaxOverviewPage />} />
                                            <Route path="taxes/:id" element={<TaxItemPage />} />
                                            <Route path="taxes/new" element={<TaxItemPage />} />
                                            <Route path="users" element={<UserOverviewPage />} />
                                            <Route path="users/:id" element={<UserItemPage />} />
                                            <Route path="users/new" element={<UserItemPage />} />
                                            <Route path="mail-templates" element={<MailTemplateOverviewPage />} />
                                            <Route path="mail-templates/:id" element={<MailTemplateItemPage />} />
                                            <Route path="mail-templates/new" element={<MailTemplateItemPage />} />
                                        </Route>

                                        <Route path="*" element={<div>404 Not Found</div>} />
                                    </Route>
                                </Route>
                            </Fragment>
                        )}
                    </Route>
                </ReactRouterRoutes>
            </React.Suspense>
        </React.Fragment>
    );
}
