import { AuthLoaderProvider } from "@app/auth";
import { CartContextProvider } from "@app/cart";
import { NotificationCarousel, NotificationManagerProvider } from "@app/common";
import { i18next } from "@app/services";
import { ErrorBoundary } from "react-error-boundary";
import { I18nextProvider } from "react-i18next";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import { AppStateContextProvider } from "../context/AppStateContextProvider";
import { queryClient } from "../services/queryClient";
import { ErrorDialog } from "./dialog/ErrorDialog";
import { Routes } from "./Routes";

export const App = () => (
    <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
            window.location.reload();
        }}>
        <QueryClientProvider client={queryClient}>
            <AppStateContextProvider>
                <I18nextProvider i18n={i18next}>
                    <NotificationManagerProvider>
                        <Router>
                            <AuthLoaderProvider>
                                <CartContextProvider>
                                    <Routes />
                                </CartContextProvider>
                            </AuthLoaderProvider>
                        </Router>
                        <NotificationCarousel />
                    </NotificationManagerProvider>
                </I18nextProvider>
            </AppStateContextProvider>
            <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
        </QueryClientProvider>
    </ErrorBoundary>
);

const ErrorFallback = ({ error, resetErrorBoundary }: any) => <ErrorDialog error={error} onRetry={resetErrorBoundary} />;
