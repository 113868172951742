import classnames from "classnames";
import React from "react";

interface IPageTitleProps {
    title: string;
    children?: React.ReactNode;
    className?: string;
}

export function PageTitle({ title, children, className }: IPageTitleProps) {
    return (
        <div className={classnames("sticky z-10 top-0 flex-shrink-0 h-24 border-b border-slate-200 flex bg-slate-50 mb-8", className)}>
            <div className="flex justify-between align-center items-center px-4 md:px-0 w-full">
                <h2 className="text-2xl font-medium tracking-tight leading-7 text-gray-700 sm:truncate">{title}</h2>
                <div>{children}</div>
            </div>
        </div>
    );
}
