import { hooks as appHooks } from "@app/app";
import { CartContext } from "@app/cart";
import { OutlineIcon } from "@app/common";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, ShoppingBagIcon, ShoppingCartIcon } from "@heroicons/react/outline";
import { Badge, Button, ButtonVariants, Spinner } from "@ui";
import classNames from "classnames";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useSyncCartItemsStock } from "../hooks";
import { ICartItem } from "../types";
import { CartBarItemList } from "./CartBarItemList";

interface ICartBarItemProps1 {
    count?: number;
    to?: string;
}

export const CartBarItem1 = ({ count = 0, to }: ICartBarItemProps1) => (
    <div className="ml-4 flow-root lg:ml-6">
        <Link to={to || "/cart"} className="group -m-2 p-2 flex items-center">
            <ShoppingBagIcon className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
            <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{count}</span>
            <span className="sr-only">items in cart, view cart</span>
        </Link>
    </div>
);

interface ICartBarItemProps {
    to?: string;
    cartUriResolver: () => string;
    cartItemUriResolver: (item: ICartItem) => string;
    catalogUriResolver: () => string;
    checkoutUriResolver: () => string;
    // items: productTypes.IProduct[] | undefined;
}

export const CartBarItem = ({ to, cartUriResolver, cartItemUriResolver, catalogUriResolver, checkoutUriResolver }: ICartBarItemProps) => {
    const items = useContext(CartContext);
    const {
        state: { project, projectId },
    } = appHooks.useAppState();
    const { mutate: syncCartStock } = useSyncCartItemsStock(projectId);
    const hasItems = !!items?.length;
    const [isSpinnerActive, setSpinnerActive] = useState(false);
    const [totalProductCount, setTotalProductCount] = useState(0);
    const [isAttentionRequired, setIsAttentionRequired] = useState(false);
    const navigate = useNavigate();
    const cartUri = useMemo(cartUriResolver, [cartUriResolver]);
    const catalogUri = catalogUriResolver();
    const checkoutUri = checkoutUriResolver();
    const canOrderOutOfStock = project?.can_order_out_of_stock;
    const { t } = useTranslation("cart");

    useEffect(() => syncCartStock, [projectId]);

    useEffect(() => {
        setSpinnerActive(true);

        const spinnerProcessId = setTimeout(() => setSpinnerActive(false), 550);

        return () => spinnerProcessId && clearTimeout(spinnerProcessId);
    }, [items]);

    useEffect(() => {
        const newTotalProductCount = items.reduce((aggr, cartItem) => aggr + cartItem.count, 0);
        const newIsAttentionRequired = canOrderOutOfStock
            ? false
            : items.find((item) => !item.product.stock || item.count > item.product.stock) !== undefined;

        setTotalProductCount(newTotalProductCount);

        setIsAttentionRequired(newIsAttentionRequired);
    }, [items, canOrderOutOfStock]);

    return (
        <Popover className="flow-root text-sm lg:relative">
            <Popover.Button className="mx-2 p-2 flex gap-x-1 items-center px-2 py-2 justify-between rounded-md text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                <div className="">
                    <ShoppingCartIcon className="h-6 w-6" aria-hidden="true" />
                </div>

                <div className="text-sm font-medium text-gray-500 px-1">
                    <Spinner isVisible={isSpinnerActive}>
                        <Badge className="bg-indigo-600" variant={totalProductCount > 0 ? "primary" : "subtle"}>
                            {totalProductCount}
                        </Badge>
                    </Spinner>
                </div>

                <div className="">
                    <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                </div>

                <span className="sr-only">{t("bar-item.sr-button-label")}</span>
            </Popover.Button>
            <Transition
                as={React.Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Popover.Panel className="absolute top-16 inset-x-0 mt-px py-4 bg-white shadow-lg sm:px-2 lg:left-auto lg:right-0 lg:top-12 lg:-mr-1.5 lg:w-70 lg:rounded-lg lg:ring-1 lg:ring-black lg:ring-opacity-5 max-h-96 overflow-y-scroll">
                    {({ open, close }) => (
                        <React.Fragment>
                            <h2 className="sr-only">{t("bar-item.header")}</h2>

                            <div className="max-w-2xl mx-auto px-4 text-left">
                                {hasItems && (
                                    <React.Fragment>
                                        <div className="text-center">
                                            <Button
                                                className={classNames("shadow-sm py-2 px-16 text-base font-medium", {
                                                    "opacity-20": isAttentionRequired,
                                                })}
                                                variant={ButtonVariants.Primary}
                                                onClick={() => {
                                                    navigate(checkoutUri);
                                                    close();
                                                }}
                                                disabled={isAttentionRequired || !hasItems}>
                                                <OutlineIcon name="chevron-right" className={"h-4 w-4 mr-2"} />
                                                {t("bar-item.button-label-checkout")}
                                            </Button>
                                        </div>

                                        <p className="mt-2 text-center">
                                            <Popover.Button
                                                as={Link}
                                                to={cartUri}
                                                onClick={() => close()}
                                                className="text-sm font-medium text-gray-700 hover:text-indigo-600">
                                                {t("bar-item.button-label-view-cart")}
                                            </Popover.Button>
                                        </p>
                                    </React.Fragment>
                                )}

                                {hasItems && <div className="border-b border-gray-200 my-4" />}

                                <CartBarItemList items={items} cartItemUriResolver={cartItemUriResolver} onClickItem={() => close()} />

                                {!hasItems && (
                                    <p className="mt-6 text-center">
                                        <Link
                                            to={catalogUri}
                                            onClick={() => close()}
                                            className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                                            {t("bar-item.link-label-continue-shopping")}
                                        </Link>
                                    </p>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};
